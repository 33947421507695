import React, {useEffect, useState} from "react";
import { utils } from "../../Utils";

const TopTotal = ({params}) => {
	const [totalSales, setTotalSales] = useState(0);
	const [totalOrders, setTotalOrders] = useState(0);
	const [totalItems, setTotalItems] = useState(0);
	
	useEffect(() => 
	{
		//console.log(params);
		if(params?.locations){
			let locals = params?.locations;
			let totalOrder = 0, totalSale = 0, totalItem = 0;
			for(let i=0; i<locals.length; i++){
				totalOrder += locals[i].totalOrders;
				totalSale += locals[i].totalSales / 100;
				totalItem = locals[i].totalItems;
				
				if(locals.length - 1 === i){
					setTotalSales(totalSale.toFixed(2));
					setTotalOrders(totalOrder);
					setTotalItems(totalItem);
				}
			}
		}
		
	}, [params]);
	
	
	return (
    <div className="row" style={{fontFamily: 'Lato'}}>
      <div className="col-lg-4">
        <div className="card card-body mb-4 shadow-sm">
          <article className="icontext">
            <span className="icon icon-sm rounded-circle alert-primary">
              <i className="text-primary fas fa-usd-circle"></i>
            </span>
            <div className="text">
				<div className="order-number">{`Total Sales`}</div> <span style={{fontSize: 20, fontWeight: 600, color: 'rgb(56, 136, 253)'}}><b>{`$${parseFloat(totalSales).toFixed(2)}` || `Please relogin`}</b></span>
            </div>
          </article>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card card-body mb-4 shadow-sm">
          <article className="icontext">
            <span className="icon icon-sm rounded-circle alert-success">
              <i className="text-success fas fa-shopping-basket"></i>
            </span>
            <div className="text">
			  <div className="order-number">{`Total Orders`}</div> <span style={{fontSize: 20, fontWeight: 600, color: 'rgb(56, 136, 253)'}}><b>{`${totalOrders}` || `Please relogin`}</b></span>
            </div>
          </article>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card card-body mb-4 shadow-sm">
          <article className="icontext">
            <span className="icon icon-sm rounded-circle alert-warning">
              <i className="text-warning fas fa-bags-shopping"></i>
            </span>
            <div className="text">
			  <div className="order-number">{`Total Items`}</div> <span style={{fontSize: 20, fontWeight: 600, color: 'rgb(56, 136, 253)'}}><b>{`${totalItems}` || `Please relogin`}</b></span>
            </div>
          </article>
        </div>
      </div>
    </div>
	);
};

export default TopTotal;
