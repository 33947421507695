import React, { useEffect, useState } from "react";
import { Layout, Breadcrumb, Divider, message } from "antd";
import { ItemList } from "../../Components";
import { utils } from "../../Utils";


const ItemScreen = ({pcount}) => {
	
	const [updatedelete, setUpdateDelete] = useState(false);
	const merchantURL = utils.MERCHANT_URL;
	const { Content } = Layout;
  
	const breadcrumbItems = [
		{ breadcrumbName: "Menu", title: "Menu" },
		{ breadcrumbName: "List", title: "List" },
	];	
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Menu Item List | Restaurant | ErrandLocal";
	}, []);
	
	const onPostAction = async (param) =>
	{	
		try{
			setUpdateDelete(false);
			const header = await utils.genHeader();
			const dataToPost = JSON.stringify(param);
			const method = 'POST';			
			const res = await utils.fetchURL(merchantURL, method, dataToPost, header);
			const data = await res.json();
			
			if(data.msg === 'Token Updated'){
				message.info('Please republish');
				localStorage.setItem('__adel_token', data.token);
				return;
			}
			
			if(data.msg === 'Success' || data.msg === 'Successful'){
				setUpdateDelete(true);
				//alert('Update Successful');
			}else{
				message.error(data.msg);
			}
		}catch(e){}
	}
	
	const onPostDelete = async (param) =>
	{	
		try{
			setUpdateDelete(false);
			const header = await utils.genHeader();
			const dataToPost = JSON.stringify(param);
			const method = 'POST';			
			const res = await utils.fetchURL(merchantURL, method, dataToPost, header);
			const data = await res.json();
			
			if(data.msg === 'Token Updated'){
				message.info('Please republish');
				localStorage.setItem('__adel_token', data.token);
				return;
			}
			
			if(data.msg === 'Success' || data.msg === 'Successful'){
				setUpdateDelete(true);
			}else{
				message.error(data.msg);
			}
		}catch(e){}
	}
	
	
	return (
    
	<Content style={{ margin: "24px 16px 0", fontFamily: "Lato" }}>
	  <div
		style={{
		  marginBottom: 16,
		  display: "flex",
		  flexDirection: "row",
		  justifyContent: "space-between",
		}}
	  >
		<Breadcrumb items={breadcrumbItems} />
	  </div>
	  <div
		className="site-layout-background"
		style={{
		  padding: 24,
		  minHeight: 360,
		  backgroundColor: "#f7faf6",
		}}
	  >
	  <h2 style={{ color: "#001529" }}>Menu Item List</h2>
	  <span style={{ opacity: 0.5 }}>List of all items published to your store</span>
	  <Divider />
	  <ItemList pcount={pcount} onPostDelete={onPostDelete} updatedelete={updatedelete} onPostAction={onPostAction}/>
	 </div>
	</Content>
        
	);
};

export default ItemScreen;
