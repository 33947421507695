import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import { utils } from "../../Utils";
import { Counter } from "../../Components";
import TopTotal from "./TopTotal";
import RouteNav from "./RouteNav";
import LatestOrder from "./LatestOrder";
import OrderManagement from "./OrderManagement";
import ProductManagement from "./ProductManagement";
import { Spin, List, Button, Modal, Input, Divider, message } from 'antd';

const Home = ({pcount, initOrders, params, setUser, setHqId, onPostAction, loading}) => {	
	
	const email = localStorage.getItem('aduseremail');
	const [adminStoresVisible, setAdminStoresVisible] = useState(false);
	const [storeVal, setStoreVal] = useState('');
	const [storeParams, setStoreParams] = useState(params);
	const sortedStoreNames = storeParams?.adminControlledStores?.sort((a, b) => a.storeName.localeCompare(b.storeName));

  //console.log(sortedStoreNames);
	
	const options = sortedStoreNames ? sortedStoreNames?.map(location => ({
		value: location?.storeId+'|'+location?.storeEmail,
		label: location?.storeName,
		country: location?.country,
		storeSwitch: location?.mainSwitch,
		status: location?.status
	})) : [];
	
	//const options = [];
	
	const switchSession = () =>
	{
		setAdminStoresVisible(true);
	}
	
	const handleStoreVal = (val) => {
		setStoreVal(val);
	};
	
	const handleSwitchModalCancel = () => {
		setAdminStoresVisible(false);
	};
	
	const handleSwitchModalOk = async() => {
		let msg;
		if(!storeVal){
			msg = 'Select a store';
			message.error(msg);
			return false;
		}		
		const [hqId, nemail] = storeVal.split('|');
		await setUser(nemail); 
		await setHqId(hqId);
		setAdminStoresVisible(false);	
		const index = await params?.adminControlledStores?.findIndex((store) => store?.storeId === hqId);
		message.info(`You are in ${params?.adminControlledStores[index]?.storeName}`);
	};
	
	
	
	const confirmSwitchSession = () => {
	  const size = 'large';
	  				  
	  return [
		<Button
		  size={size}
		  onClick={handleSwitchModalCancel}
		  key={uuidv4()}
		>
		  CLOSE
		</Button>,
		<Button
		  size={size}
		  type="primary"
		  onClick={()=> handleSwitchModalOk()}
		  key={uuidv4()}
		  danger
		>
		  SUBMIT
		</Button>,
	  ];
  };
	
  
	return (
    <>
      <section className="content-main">
        <div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
			<div className="content-header1">			
				<div><div onClick={()=> switchSession()} className="btn btn-warning" style={{color: '#ffffff'}}>Switch session</div></div>
			</div>			
			<Counter pcount={pcount} />
		</div>
			
				<TopTotal params={params}/>
				
				{!loading && (<>
					<div className="row">          
						<OrderManagement />
						<ProductManagement />
					</div>					
					<RouteNav />
				</>)}
				
				{loading && (<div style={{height: 400, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
				<Spin size="large" />
			</div>)}
			
        {/* LATEST ORDER */}
        <div className="card mb-4 shadow-sm">
          <LatestOrder initOrders={initOrders} params={params} setUser={setUser} setHqId={setHqId} onPostAction={onPostAction}/>
        </div>				
				
				<div className="order-comment">
				<Modal
					title={`Select location`}
					open={adminStoresVisible}
					footer={confirmSwitchSession()}
					//onOk={handlePauseModalOk}			
					onCancel={handleSwitchModalCancel}
					maskClosable={false}
					closable={true}
				>
					<Divider />
					<select className="form-control py-3 tags-editor" name="storeVal" title="Store Location" id="storeLocation" value={storeVal} onChange={(e) => handleStoreVal(e.target.value)}>
						<option key={uuidv4()} value="">Select a store</option>
						{options[0]?.value && options?.map(option => (
							<option key={uuidv4()} value={option.value}>{option.storeSwitch} - {option.label} - {option.country}</option>
						))}
					</select>
				</Modal>	
				</div>
      </section>
			
    </>
  );
};

export default Home;
