import React from 'react';

function Message({ message, user }) {
  const isUser = user?.id === message?.user?.id;

  return (
    <div className={`message ${isUser && 'message__user'}`}>
      <img
        className="message__image"
        src={user?.imageUri}
        alt={message?.user?.name}
      />
      <div className="message__content">
        <span className="message__name">{message?.user?.name}</span>
        {message?.content}
        {message?.image && (<><br/><span style={{paddingLeft: '2.5%', paddingRight: '2.5%', paddingTop: 10}}>
          <img src={message?.image} width="95%" alt="image"/>
        </span></>)}
        <br/><span className="message__timestamp">
          {new Date(message?.createdAt * 1000).toLocaleString()}
        </span>
      </div>
    </div>
  );
}

export default Message;
