import React, { useState, useEffect } from "react";
import { Layout, Breadcrumb, Divider } from "antd";
import { EditItem } from "../../Components";
import { useParams } from 'react-router-dom';


const EditItemScreen = ({pcount, params, loaded}) => {
  const { Content } = Layout;
  const { itemId } = useParams();
  const [ update, setUpdate ] = useState(false);
  const [ dataFetched, setDataFetched ] = useState([]);
  
  
  const breadcrumbItems = [
    { breadcrumbName: "Menu", title: "Menu" },
    { breadcrumbName: "List", title: "List" },
    { breadcrumbName: "Edit Item", title: "Edit Item" },
  ];
  
  useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Edit Menu Item | Restaurant | ErrandLocal";
  }, []);

  return (
	<Content style={{ margin: "24px 16px 0", fontFamily: "Lato" }}>
	  <div
		style={{
		  marginBottom: 16,
		  display: "flex",
		  flexDirection: "row",
		  justifyContent: "space-between",
		}}
	  >
		<Breadcrumb items={breadcrumbItems} />
	  </div>
	  <div
		className="site-layout-background"
		style={{
		  padding: 24,
		  minHeight: 360,
		  backgroundColor: "#f7faf6",
		}}
	  >
		<h2 style={{ color: "#001529" }}>Edit Item</h2>
		<span style={{ opacity: 0.5 }}>To edit item, click on the edit icon under the "Action" column.</span><br/><span style={{ opacity: 0.5 }}>Addons and descriptions can also be added, when you click on the "+" icon.</span>
		<Divider />
		<EditItem itemId={itemId} pcount={pcount} update={update} setUpdate={setUpdate} params={params} loaded={loaded} dataFetched={dataFetched} setDataFetched={setDataFetched}/>
	  </div>
	</Content>
  );
};

export default EditItemScreen;
