import React from 'react';
import Message from '../Message';

function Chat({ chatData }) {
	
	const array = chatData?.messages;
	// Reverse the array using a for loop.
	const reversedArray = [];
	for (let i = array.length - 1; i >= 0; i--) {
		reversedArray.push(array[i]);
	}	
	
  return (
    <div className="chat">
			{reversedArray?.length === 0 && (<div>No conversation was recorded</div>)}
      {reversedArray?.map((message) => (
        <Message key={message?.id} message={message} user={chatData?.users[0]} />
      ))}
    </div>
  );
}

export default Chat;
