import React, {useEffect, useState, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { createTheme } from 'react-data-table-component';
import { useSelector, useDispatch } from "react-redux";
import { Counter } from "../../Components";
import { OrderStatus } from "../../Includes/NavSlice";
import { utils } from "../../Utils";
import { v4 as uuidv4 } from "uuid";
import { Layout, Breadcrumb, Button, Modal, Input, Divider, message, Drawer } from 'antd';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<>
	<input
	id="search"
	type="text"
	placeholder="Filter By Order ID..."
	className="form-control p-2 tags-editor"
	value={filterText}
	onChange={onFilter}
	/>
	</>
);

const Export = ({ onExport }) => <div><div onClick={e => onExport(e.target.value)} className="btn btn-primary">Pay All</div></div>;

const renderOrderAmount = (stats) => {	
	return <span style={{color: "black"}}><b>{stats}</b></span>;
};

const DriverSettlement = ({pcount, initOrders}) => {
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [adminStoresVisible, setAdminStoresVisible] = useState(false);
	const [normalView, setNormalView] = useState(true);
	const [orderData, setOrderData] = useState('');
	
	const courierData = initOrders.reduce((acc, offer) => {		
		const { courierId, mainfee, fee, tip, orderId, settlement } = offer;
		if(settlement === '0'){
			if (!acc[courierId]) {
				acc[courierId] = {
					mainFee: mainfee,
					courierFee: fee,
					courierTip: tip,
					orderIds: [orderId]
				};
				} else {
				acc[courierId].mainFee += mainfee;
				acc[courierId].courierFee += fee;
				acc[courierId].courierTip += tip;
				acc[courierId].orderIds.push(orderId);
			}
		}
		return acc;
		}, {});
	
	// Convert courierData object to an array of objects
	const orders = Object.keys(courierData).map((courierId, index) => ({
		index: (index + 1),
		courierId: courierId,
		totalMainFee: courierData[courierId].mainFee,
		totalCourierFee: courierData[courierId].courierFee,
		totalCourierTip: courierData[courierId].courierTip,
		mergedOrders: courierData[courierId].orderIds.join('|')
	}));
	
	const handleSwitchModalCancel = () => {
		setAdminStoresVisible(false);
	};
	
	const handleView = async(view) => {
		if(view === 'normalView')
		{
			setNormalView(true);			
		}
	}
	
	const handleInfo = async(data) => {
		setOrderData(data.split('|').join(', '));		
		setAdminStoresVisible(true);
		setNormalView(true);		
	};
	
	const confirmSwitchSession = () => {
	  const size = 'large';
	  				  
	  return [
		<Button
		  size={size}
		  onClick={handleSwitchModalCancel}
		  key={uuidv4()}
		>
		  CLOSE
		</Button>
	  ];
  };
	
	function convertArrayOfObjectsToCSV(array) {
		let result;
		
		const columnDelimiter = ',';
		const lineDelimiter = '\n';
		const keys = Object.keys(array[0]);
		
		result = '';
		result += keys.join(columnDelimiter);
		result += lineDelimiter;
		
		array.forEach(item => {
			let ctr = 0;
			keys.forEach(key => {
				if (ctr > 0) result += columnDelimiter;
				result += item[key];
				ctr++;
			});
			result += lineDelimiter;
		});
		
		return result;
	}
	// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
	function downloadCSV(array) {
		const link = document.createElement('a');
		let csv = convertArrayOfObjectsToCSV(array);
		if (csv == null) return;
		const filename = 'export.csv';
		if (!csv.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csv}`;
		}
		link.setAttribute('href', encodeURI(csv));
		link.setAttribute('download', filename);
		link.click();
	}
	
	const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(orders)} />, []);
	
	const renderSwitch = (data) => {
		return <span className="pointer" onClick={()=> handleInfo(`${data}`)} style={{color: "#1677ff"}}><b>{`View Orders`}</b></span>;		
	};
	
	
	createTheme('solarized', {
		text: {
			primary: '#4fa607',
			secondary: '',
		},
		background: {
			default: '',
		},
		context: {
			background: '#cb4b16',
			text: '#cdd6d9',
		},
		divider: {
			default: '#4fa607',
		},
		action: {
			button: 'rgba(0,0,0,.54)',
			hover: 'rgba(0,0,0,.08)',
			disabled: 'rgba(0,0,0,.12)',
		},
	}, 'light');
	
	const columns = [		
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>S/N</div>,
			selector: row => row.index,
			sortable: true,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '100px',
		},
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>DRIVER ID</div>,
			selector: row => row.courierId,
			sortable: false,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '150px',
		},	
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>TOTAL FEES</div>,
			selector: row => renderOrderAmount('$'+parseFloat(row.totalCourierFee).toFixed(2)),
			sortable: true,
			wrap: true,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '150px',
		},				
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>TOTAL TIPS</div>,
			selector: row => renderOrderAmount('$'+parseFloat(row.totalCourierTip).toFixed(2)),
			sortable: true,
			wrap: true,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '150px',
		},
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>ORDERS</div>,
			selector: row => renderSwitch(row.mergedOrders),
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '150px',
		},				
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>TOTAL</div>,
			selector: row => renderOrderAmount('$'+parseFloat(row.totalMainFee).toFixed(2)),
			sortable: true,
			wrap: true,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '150px',
		},		
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>SETTLEMENT STATUS</div>,
			selector: row => 'Pending',
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '300px',
		},
	];
	
	return (
		<section className="content-main">
		<div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
		<div className="content-header1">
		{actionsMemo}
		</div>
		
		<Counter pcount={pcount} />
		</div>
		
		<div className="card mb-4 shadow-sm">
		<header className="card-header bg-white">
		<div className="row gx-3 py-3">
		<div className="col-lg-4 col-md-6 me-auto">				
		{``}              
		</div>
		<div className="col-lg-2 col-6 col-md-6">
		<select className="form-select tags-editor">
		<option>All</option>
		</select>
		</div>
		</div>
		</header>
		<div className="card-body">
		<div className="table-responsive" style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>
		<DataTable
		columns={columns}
		data={orders}
		pagination
		paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
		//striped
		responsive
		theme="light"
		highlightOnHover
		/>
		
		<div className="order-comment">
			<Drawer
				style={{fontFamily: "Lato"}}
				title={`Orders`}
				open={adminStoresVisible}
				footer={confirmSwitchSession()}
				maskClosable={false}
				closable={false}								
				placement="right"        
				onClose={handleSwitchModalCancel}
				size={`large`}
				//classNames={classNames}
				//styles={drawerStyles}
			>
				<Divider />
				{normalView && (<div style={{fontSize: 18, fontFamily: "Lato"}}>
				<div style={{fontSize: 18}}><span style={{maxWidth: '100%'}}>{`${orderData}`}</span></div></div>)}
				<Divider />
				
			</Drawer>
		</div>
		</div>
		</div>
		</div>
		</section>
	);
};


const DriverSettlementScreen = ({pcount, initialData, loaded}) => 
{	
	const initList = typeof initialData === "object" ? initialData : JSON.parse(initialData);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [settlements, setSettlements] = useState(initList);
	const [level, setLevel] = useState('');
	const { Content } = Layout;	
  
	const breadcrumbItems = [
		{ breadcrumbName: "Drivers", title: "Drivers" },
		{ breadcrumbName: "Settlement", title: "Settlement" },
	];
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("aduseremail");
		setEmail(email ?? '');
		// eslint-disable-next-line
	}, []);	
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Drivers Settlement | Restaurant | ErrandLocal";
	}, []);
	
	useEffect(() => {
	  if (loaded && localStorage.getItem('aduserstoreId')) {
			const interval = setInterval(async() => {
				let loguser = '', getuser = {};
				if(localStorage.getItem('adloguseremail')){
					loguser = await localStorage.getItem('adloguseremail');
					getuser = await utils.getUser(loguser);
				}			
				if(getuser && getuser?.role === 'Level2'){
					setLevel(getuser?.role);
					await fetchSettlements();
				}else{
					setLevel('');	
				}
			}, 120000);
			return () => clearInterval(interval);
	  }
	}, [loaded]);
	
	const fetchSettlements = async () => 
	{
		try{
			if(!localStorage.getItem('aduseremail')){
				return;
			}			
			const email = localStorage.getItem('aduseremail');			
			const header = await utils.genHeader();
			const target = await utils.DRIVER_CALL+'?settlements='+email;
			const data = await utils.fetchURL(target, 'GET', '', header);
			const settlesFromServer = await data.json();
			
			const settlesData = await utils.cipherEncode(JSON.stringify(settlesFromServer?.data));
			await localStorage.setItem('__adel_settles', settlesData);
			const settlesInfo = await utils.cipherDecode(settlesData);
			if(settlesInfo.length > 0 && settlesInfo !== settlements){
				setSettlements(settlesInfo);
			}			
		}catch(e){}
	}
		
		
	return (
			<Content style={{ margin: "24px 16px 0", fontFamily: "Lato" }}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>Settlement</h2>
			<span style={{ opacity: 0.5 }}>List of all settlements</span>
			<Divider />
			{settlements?.length > 0 && (<DriverSettlement pcount={pcount} initOrders={settlements} />)}
		  </div>
			</Content>
		);
		
		
};

export default DriverSettlementScreen;
