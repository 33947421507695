import React, { useState, useEffect, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from "uuid";
import DataTable, { createTheme } from 'react-data-table-component';
import { useSelector, useDispatch } from "react-redux";
import { setAllOrders, OrderStatus } from "../../Includes/NavSlice";
import { utils } from "../../Utils";
import { MapList, Chat } from "../../Components";
import { List, Button, Modal, Input, Divider, message, Drawer } from 'antd';
//import { createStyles, useTheme } from 'antd-style';
/*
const useStyle = createStyles(({ token }) => ({
  'my-drawer-body': {
    background: token.blue1,
  },
  'my-drawer-mask': {
    boxShadow: `inset 0 0 15px #fff`,
  },
  'my-drawer-header': {
    background: token.green1,
  },
  'my-drawer-footer': {
    color: token.colorPrimary,
  },
  'my-drawer-content': {
    borderLeft: '2px dotted #333',
  },
}));
*/
const renderOrderType = (stats) => {
    const statusToColor = {
		"Pickup": "alert-danger declined",
		"Delivery": "alert-warning warned",
		"Errand": "alert-info info",
    };
	const statusToVal = {
		"Pickup": "PICK UP",
		"Delivery": "DELIVERY",
		"Errand": "ERRAND",
    };
	const el = statusToColor[stats];

	//return <span className={`${el} orderstatus rounded-pill`}>{statusToVal[stats]}</span>;
	return statusToVal[stats];
};

const renderOrderAmount = (stats) => {   

	return <span style={{color: "black"}}><b>{stats}</b></span>;
};

const renderOrderStatus = (orderStatus, type) => {
	if(type === 'Errand'){
		return '-';
	}
  const statusToColor = {
		[OrderStatus.NEW]: "alert-info",
		[OrderStatus.COOKING]: "alert-warning",
		[OrderStatus.AT_STORE]: "alert-warning",
		[OrderStatus.COURIER_PICKED_UP]: "alert-warning",
		[OrderStatus.STORE_READY_FOR_PICKUP]: "alert-info",
		[OrderStatus.COURIER_READY_FOR_PICKUP]: "alert-info",
		[OrderStatus.STORE_COMPLETED]: "alert-success",
		[OrderStatus.COURIER_COMPLETED]: "alert-success",
		[OrderStatus.STORE_ACCEPTED]: "alert-info",
		[OrderStatus.COURIER_ACCEPTED]: "alert-info",
		[OrderStatus.STORE_DECLINED]: "alert-danger",
		[OrderStatus.COURIER_DECLINED]: "alert-danger",
		[OrderStatus.PAYMENT_DECLINED]: "alert-warning",
		[OrderStatus.CANCELLED]: "alert-danger",
		[OrderStatus.PENDING]: "alert-info",
  };
	const el = statusToColor[OrderStatus[orderStatus]];

	//return <span className={`${el} orderstatus rounded-pill`}>{OrderStatus[orderStatus]}</span>;
	return OrderStatus[orderStatus];
};

// Define a custom comparison function
function customSort(a, b) {
	// Check for STORE_COMPLETED and COURIER_COMPLETED status
	if (
		a.storestatus === "STORE_COMPLETED" && a.courierstatus === "COURIER_COMPLETED" &&
		!(b.storestatus === "STORE_COMPLETED" && b.courierstatus === "COURIER_COMPLETED")
	) {
		return 1; // a comes after b
	} else if (
		b.storestatus === "STORE_COMPLETED" && b.courierstatus === "COURIER_COMPLETED" &&
		!(a.storestatus === "STORE_COMPLETED" && a.courierstatus === "COURIER_COMPLETED")
	) {
		return -1; // b comes after a
	} else if (
		a.storestatus !== "STORE_COMPLETED" && a.courierstatus === "COURIER_COMPLETED" &&
		!(b.storestatus !== "STORE_COMPLETED" && b.courierstatus === "COURIER_COMPLETED")
	) {
		return 1;
	} else if (
		b.storestatus !== "STORE_COMPLETED" && b.courierstatus === "COURIER_COMPLETED" &&
		!(a.storestatus !== "STORE_COMPLETED" && a.courierstatus === "COURIER_COMPLETED")
	) {
		return -1;
	}

	// If not both have STORE_COMPLETED and COURIER_COMPLETED status, sort by subtotal
	return a.subtotal - b.subtotal;
}
	

const LatestOrder = ({initOrders, params, setUser, setHqId, onPostAction}) => {
	const mapRef = useRef(null);
	
	const [adminStoresVisible, setAdminStoresVisible] = useState(false);
	const [normalView, setNormalView] = useState(true);
	const [manageDriverView, setManageDriverView] = useState(false);
	const [conversationView, setConversationView] = useState(false);
	const [driversView, setDriversView] = useState(false);
	const [orderNoteView, setOrderNoteView] = useState(false);
	const [selectedOrder, setSelectedOrder] = useState({});
	const [orderId, setOrderId] = useState("");
	
	
	const [orderComment, setOrderComment] = useState("");
	const [chatData, setChatData] = useState({});
	const [selected, setSelected] = useState("");
	const [markers, setMarkers] = useState([]);
	const logemail = localStorage.getItem('adloguseremail');	
	let dispatch = useDispatch();		
	
	
	initOrders = typeof initOrders === "object" ? initOrders : JSON.parse(initOrders);
	// Sort the array using the custom comparison function
	initOrders.sort(customSort);
	//console.log(initOrders);
	
	let orders = initOrders;
	let latestorders = [];
	if(orders[0]?.storeId){
		orders.sort((a, b) => b.createdTimestamp - a.createdTimestamp);
		orders = orders.map((order, i) => {
			let index = i+1;		
			//if(index < 6){
				if(latestorders.indexOf({ ...order, index }) === -1){
					latestorders.push({ ...order, index });					
				}			
			//}
			return { ...order, index };		
		});
	}else if(initOrders[0]?.storeId){
		dispatch(setAllOrders(initOrders));
		orders = initOrders;
		orders.sort((a, b) => b.createdTimestamp - a.createdTimestamp);
		orders = orders.map((order, i) => {
			let index = i+1;		
			//if(index < 6){
				if(latestorders.indexOf({ ...order, index }) === -1){
					latestorders.push({ ...order, index });
				}			
			//}
			return { ...order, index };		
		});
	}
	
	
	
	useEffect(() => 
	{	
		if(orderId)
		{
			const index = orders.findIndex((order) => order?.orderId === orderId);
			if (index === -1) {
				message.error('Unable to find order');
				return;
			}
			const newOrder = orders[index];
			setSelectedOrder(newOrder);
			setOrderId(newOrder?.orderId);
			
			const newOrderComment = newOrder?.comment ? newOrder?.comment.replace(/\|\|/g, "<br/>") : "";
			setOrderComment(newOrderComment);
			setChatData(newOrder?.chat);
			
			let storeInfo = newOrder?.storeGps;
			if(storeInfo?.title === 'store')
			{
				const contentString = '';	
				storeInfo.info = contentString;
			}
			
			let destinationInfo = newOrder?.destinationGps;
			if(destinationInfo?.title === 'destination')
			{
				const destinationString = '';	
				destinationInfo.info = destinationString;
			}			
			
			let drivers = newOrder?.driversGps;
			let counter = 1;
			let newDrivers = [storeInfo, destinationInfo];
			for(let i=0; i<drivers.length; i++){
				let driver = drivers[i];		
				newDrivers.push(driver);
			}
			setMarkers(newDrivers);	
		}
	}, [orderId]);
	
	const handleSwitch = async(hqId) => {		
		if(hqId){
			const index = await params?.adminControlledStores?.findIndex((store) => store?.storeId === hqId);
			const nemail = await params?.adminControlledStores[index]?.storeEmail;							
			await setUser(nemail); 
			await setHqId(hqId);
			message.info(`You are in ${params?.adminControlledStores[index]?.storeName}`);
			handleSwitchModalCancel();
		}		
	};
		
	const handleView = async(view) => {
		if(view === 'normalView')
		{
			setNormalView(true);
			setManageDriverView(false);
			setConversationView(false);
			setDriversView(false);
			setOrderNoteView(false); 
			setSelected('');
		}
		else if(view === 'manageDriverView')
		{
			setNormalView(false);
			setManageDriverView(true);
			setConversationView(false);
			setDriversView(false);
			setOrderNoteView(false); 
			setSelected('');
		}
		else if(view === 'conversationView')
		{
			setNormalView(false);
			setManageDriverView(false);
			setConversationView(true);
			setDriversView(false);
			setOrderNoteView(false); 
			setSelected('');
		}
		else if(view === 'driversView')
		{
			setNormalView(false);
			setManageDriverView(false);
			setConversationView(false);
			setDriversView(true);
			setOrderNoteView(false); 
			setSelected('');
		}
		else if(view === 'orderNoteView')
		{
			setNormalView(false);
			setManageDriverView(false);
			setConversationView(false);
			setDriversView(false);
			setOrderNoteView(true); 
			setSelected('');
		}
	};
	
	const searchCoordinate = (lat, lng) => {};	
	
	const handleInfo = async(orId) => {
		setOrderId(orId);		
		setAdminStoresVisible(true);
		setNormalView(true);
		setManageDriverView(false);
		setConversationView(false);
		setDriversView(false);
		setOrderNoteView(false);		
		setSelected('');
	};
	
	const handleSwitchModalCancel = () => {
		setAdminStoresVisible(false);
	};
	
	const handleSelect = (value) => {
		//console.log('Select', value);
		setSelected(value);
	};
	
	const getTravelTime = async(driverId) => {
		
		const orderDestinationGps = selectedOrder.destinationGps;
		const orderStoreGps = selectedOrder.storeGps;
		const { lat, lng } = selectedOrder.courierGps;
		const meterPerMile = 1609.34;
		const start = [lat, lng];
		const end = [orderDestinationGps?.lat, orderDestinationGps?.lng];
		const distanceInMeters = utils.gis.calculateDistance(start, end);
		const miles = parseFloat(distanceInMeters / meterPerMile).toFixed(2);
		
		const end2 = [orderStoreGps?.lat, orderStoreGps?.lng];
		const distanceInMeters2 = utils.gis.calculateDistance(start, end2);
		const miles2 = parseFloat(distanceInMeters2 / meterPerMile).toFixed(2);
		
		alert(`Distance to Delivery Point ~${miles} miles\n\nDistance to Pickup Point ~${miles2} miles`);		
	};
	
	const handleNote = async() => {
		const orderNote = document.getElementById('orderNote').value;
		if(!orderNote){
			message.error('Comment is required');
			return;
		}
		const output = {
			rtype : 'ordernote',
			orderId : orderId,
			orderDriver : selectedOrder.courier,
			orderNote : selectedOrder.comment,
			logemail : logemail,
		};		
		onPostAction(output);	
	};	
	
	const adjustDistance = (orderId, route) => {		
		const output = {
			rtype : 'adjustdistance',
			orderId : orderId,
			orderDriver : selectedOrder.courier,
			route : route,
			logemail : logemail,
		};		
		onPostAction(output);	
	};
	
	const sendNotification = (driverType) => {
		if(window.confirm('Peak activity notification will be sent to non-scheduled drivers')){			
			const output = {
				rtype : 'sendnotification',
				orderId : orderId,
				driverClass : driverType,
				logemail : logemail,
			};		
			onPostAction(output);		
		}
	};
	
	const assignDriver = (driverId, orderId) => {
		const output = {
			rtype : 'assigndriver',
			orderId : orderId,
			orderDriver : driverId,
			logemail : logemail,
		};		
		onPostAction(output);	
	};
	
	const unAssignDriver = (driverId, orderId) => {
		const output = {
			rtype : 'unassigndriver',
			orderId : orderId,
			orderDriver : driverId,
			logemail : logemail,
		};		
		onPostAction(output);	
	};
	
	const disableCode = (orderId) => {
		const output = {
			rtype : 'disablecode',
			orderId : orderId,
			orderDriver : selectedOrder.courier,
			logemail : logemail,
		};		
		onPostAction(output);	
	};
	
	
	const isCourierAssigned = (selectedCourier) => {
		const driver = markers.find(driver => driver?.driverId === selectedCourier);
		const out = driver ? (driver.storeTravel / 1609.34).toFixed(2)+' Miles' : '';
		return out;
	};

		
	const confirmSwitchSession = () => {
	  const size = 'large';
	  				  
	  return [
		<Button
		  size={size}
		  onClick={handleSwitchModalCancel}
		  key={uuidv4()}
		>
		  CLOSE
		</Button>
	  ];
  };
	
	const renderSwitch = (data) => {
		let dataArr = data.split('|');
		const orId = dataArr[0];
		const hqId = dataArr[1];
		const type = dataArr[2];
		
		if(type.toLowerCase() === 'errand'){
			return <span className="pointer" onClick={()=> handleInfo(`${orId}`)} style={{color: "#1677ff"}}><b>{orId}</b></span>;
		}else{
			return <span><span className="pointer" onClick={()=> handleInfo(`${orId}`)} style={{color: "#1677ff"}}><b>{orId}</b></span> | <span className="pointer" onClick={()=> handleSwitch(`${hqId}`)} style={{color: "#1677ff"}}><b>Go to store</b></span></span>;
		}
		return null;
		
	};
	
	createTheme('solarized', {
	  text: {
		primary: '#4fa607',
		secondary: '',
	  },
	  background: {
		default: '',
	  },
	  context: {
		background: '#cb4b16',
		text: '#cdd6d9',
	  },
	  divider: {
		default: '#4fa607',
	  },
	  action: {
		button: 'rgba(0,0,0,.54)',
		hover: 'rgba(0,0,0,.08)',
		disabled: 'rgba(0,0,0,.12)',
	  },
	}, 'light');
	
	const columns = [		
		{
			name: <div style={{fontSize: 15, fontWeight: 600, fontFamily: 'Lato'}}>S/N</div>,
			selector: row => row.index,
			sortable: true,
			style: {
				fontSize: 15,
				fontWeight: 500,
			},
			width: '90px',
		},	
		{
			name: <div style={{fontSize: 15, fontWeight: 600, fontFamily: 'Lato'}}>TIME</div>,
			selector: row => <span style={{color: '#001529', opacity: 0.7, fontWeight: 600, fontSize: 15}}>{utils.dateToString(row.createdTimestamp)}</span>,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '230px',
		},
		{
			name: <div style={{fontSize: 15, fontWeight: 600, fontFamily: 'Lato'}}>ORDER ID</div>,
			selector: row => renderSwitch(`${row.orderId}|${row.hqId}|${row.deliveryType}`),
			sortable: false,
			style: {
				fontSize: 15,
				fontWeight: 500,
			},
			width: '250px',
		},
		{
			name: <div style={{fontSize: 15, fontWeight: 600, fontFamily: 'Lato'}}>ORDER TYPE</div>,
			selector: row => renderOrderType(row.deliveryType),
			sortable: true,
			style: {
				fontSize: 15,
				fontWeight: 500,
			},
			width: '200px',
		},
		{
			name: <div style={{fontSize: 15, fontWeight: 600, fontFamily: 'Lato'}}>DRIVER</div>,
			selector: row => renderOrderStatus(row.courierstatus),
			sortable: true,
			wrap: false,
			style: {
				fontSize: 15,
				fontWeight: 500,
			},
			width: '250px',
		},	
		{
			name: <div style={{fontSize: 15, fontWeight: 600, fontFamily: 'Lato'}}>STORE</div>,
			selector: row => renderOrderStatus(row.storestatus, row.deliveryType),
			sortable: true,
			wrap: false,
			style: {
				fontSize: 15,
				fontWeight: 500,
			},
		}
	];
	
	let loguser = '';
	
	if(localStorage.getItem('adloguseremail')){
		loguser = localStorage.getItem('adloguseremail');
	}
	
	const getUser = utils.getUser(loguser);
	
	if(getUser && getUser.role === 'Level2'){
		return (
			<div className="card-body">
				<h5 className="card-title">All Latest Orders</h5>
						<div className="table-responsive">
						<DataTable
							columns={columns}
							data={latestorders}
							striped
							responsive
							theme="light"
							highlightOnHover
						/>
						
						<div className="order-comment">
							<Drawer
								style={{fontFamily: "Lato"}}
								title={`#${orderId} Details: ${renderOrderStatus(selectedOrder.courierstatus)}`}
								open={adminStoresVisible}
								footer={confirmSwitchSession()}
								maskClosable={false}
								closable={false}								
								placement="right"        
								onClose={handleSwitchModalCancel}
								size={`large`}
								//classNames={classNames}
								//styles={drawerStyles}
							>
								<Divider />
								{normalView && (<div style={{fontSize: 18, fontFamily: "Lato"}}>
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Store Name:</span><span>{selectedOrder.storename}</span></div>
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Store Address:</span><span>{`${selectedOrder.storeaddress}, ${selectedOrder.storecity}, ${selectedOrder.storestate}, ${selectedOrder.storezip}`}</span></div>
									
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Destination Name:</span><span>{selectedOrder.destinationname}</span></div>
									<div style={{fontSize: 18, marginBottom: 20}}><span style={{marginRight: 20}}>Destination Address:</span><span>{`${selectedOrder.destinationaddress}, ${selectedOrder.destinationcity}, ${selectedOrder.destinationstate}, ${selectedOrder.destinationzip}`}</span></div>
									
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Order #:</span><span>{orderId}</span></div>
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Order Type:</span><span>{renderOrderType(selectedOrder.deliveryType)}</span></div>
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Email:</span><span>{selectedOrder.email}</span></div>
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Driver Assigned?:</span><span>{`${selectedOrder.courier ? 'YES' : 'NO'}`} {selectedOrder.courier && selectedOrder.courierstatus !== 'COURIER_COMPLETED' && (<span> - <span className="pointer" onClick={()=> getTravelTime(selectedOrder.courier)}>Get Travel Time</span></span>)}{!selectedOrder.courier && (<span> - <span className="pointer" onClick={()=> handleView(`driversView`)}>Find Driver</span></span>)}</span></div>
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Code Required?:</span><span>{`${selectedOrder.iscode ? 'YES' : 'NO'}`} {selectedOrder.iscode && selectedOrder.courierstatus !== 'COURIER_COMPLETED' && (<span> - <span className="pointer" onClick={()=> disableCode(orderId)}>Disable Code</span></span>)}</span></div>
									
									{selectedOrder?.deliveryType && selectedOrder?.deliveryType.toLowerCase() !== 'errand' && (<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Store Due:</span><span>{renderOrderAmount('$'+parseFloat((parseFloat(selectedOrder.subtotal) / 100)).toFixed(2))}</span></div>)}
									
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Driver Due (+Tip):</span><span>{renderOrderAmount('$'+parseFloat((parseFloat(selectedOrder.deliveryFee + selectedOrder.deliveryTip) / 100)).toFixed(2))}</span></div>
									
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Driver Due Paid (+Tip):</span><span>{renderOrderAmount('$'+parseFloat((parseFloat(selectedOrder.paidDeliveryFee + selectedOrder.paidDeliveryTip) / 100)).toFixed(2))}</span></div>
									
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Coupon Code:</span><span>{selectedOrder.couponCode}</span></div>
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Amount Paid:</span><span>{renderOrderAmount('$'+parseFloat((parseFloat(selectedOrder.total) / 100)).toFixed(2))}</span></div>
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Amount Waived:</span><span>{renderOrderAmount('$'+parseFloat((parseFloat(selectedOrder.total - selectedOrder.deliveryFee - selectedOrder.deliveryTip - selectedOrder.salesTax - selectedOrder.serviceFee - selectedOrder.subtotal) / 100)).toFixed(2))}</span></div>
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Driver Status:</span><span>{renderOrderStatus(selectedOrder.courierstatus)}</span></div>
									
									{selectedOrder?.deliveryType && selectedOrder?.deliveryType.toLowerCase() !== 'errand' && (<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Store Status:</span><span>{renderOrderStatus(selectedOrder.storestatus)}</span></div>)}
									
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Created Time:</span><span>{utils.dateToString(selectedOrder.createdTimestamp)}</span></div>
									<div style={{fontSize: 18, marginBottom: 20}}><span style={{marginRight: 20}}>Delivery Time:</span><span>{utils.dateToString(selectedOrder.localTimestamps)}</span></div>
									{/*- Add Amount waved, paid, real total, amount paid to driver &  to the back end.*/}								
									
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Driver Margin:</span><span>{renderOrderAmount('$'+parseFloat((parseFloat(selectedOrder.deliveryFeeMargin) / 100)).toFixed(2))}</span></div>
									<div style={{fontSize: 18, marginBottom: 20}}><span style={{marginRight: 20}}>Service Fee:</span><span>{renderOrderAmount('$'+parseFloat((parseFloat(selectedOrder.serviceFee) / 100)).toFixed(2))}</span></div>
									
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Subtotal:</span><span>{renderOrderAmount('$'+parseFloat((parseFloat(selectedOrder.subtotal) / 100)).toFixed(2))}</span></div>
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Tax:</span><span>{renderOrderAmount('$'+parseFloat((parseFloat(selectedOrder.salesTax) / 100)).toFixed(2))}</span></div>									
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Driver Fee:</span><span>{renderOrderAmount('$'+parseFloat((parseFloat(selectedOrder.deliveryFee) / 100)).toFixed(2))}</span></div>
									<div style={{fontSize: 18, marginBottom: 20}}><span style={{marginRight: 20}}>Driver Tip:</span><span>{renderOrderAmount('$'+parseFloat((parseFloat(selectedOrder.deliveryTip) / 100)).toFixed(2))}</span></div>
									
									{renderOrderStatus(selectedOrder.courierstatus) !== 'COMPLETED' && (<div style={{fontSize: 18, alignItem: 'center', display: 'flex', justifyContent: 'space-between', marginBottom: 15}}><span className="pointer" onClick={()=> handleView(`driversView`)}><b>Scheduled Drivers</b></span><span className="pointer" onClick={()=> handleView(`manageDriverView`)}><b>Delivery Distance</b></span></div>)}
									
									<div style={{fontSize: 18, alignItem: 'center', display: 'flex', justifyContent: 'space-between'}}><span className="pointer" onClick={()=> handleView(`orderNoteView`)}><b>Note on Order</b></span><span className="pointer" onClick={()=> handleView(`conversationView`)}><b>Conversation</b></span></div>
																		
									<Divider />
										{selectedOrder?.hqId && selectedOrder.hqId && (<div style={{fontSize: 18, alignItem: 'center', display: 'flex', justifyContent: 'space-between'}}><span className="pointer" onClick={()=> handleSwitch(`${selectedOrder.hqId}`)} style={{color: "#1677ff"}}><b>Go to store</b></span> <span className="pointer" onClick={()=> sendNotification('unscheduled')}>{`Send Notification`}</span></div>)}
										{selectedOrder?.deliveryType && selectedOrder?.deliveryType.toLowerCase() === 'errand' && (<div style={{fontSize: 18, alignItem: 'center', display: 'flex', justifyContent: 'space-between'}}><span></span> <span className="pointer" onClick={()=> sendNotification('unscheduled')}>{`Send Notification`}</span></div>)}
									<Divider />
									
									{/*<div style={{fontSize: 18}}><span style={{marginRight: 20}}>COMING SOON:</span><span>Order Action - Conversation, Assign, Find Drivers, Adjust Pickup and Delivery Distance, Disable Delivery Code, Show Current Location of Courier</span></div>
									<Divider />*/}
									
								</div>)}
								
								{conversationView && (<div style={{fontSize: 18, fontFamily: "Lato"}}>
								<div style={{fontSize: 18}}><span style={{marginRight: 20, color: '#1677ff'}}>{`${chatData.length !== 0 && chatData?.users[0] ? chatData?.users[0]?.name+" and "+chatData?.users[1]?.name+" " : ""}Chat Conversation.`}</span><span></span></div>
									<Divider />
									
									<div style={{fontSize: 18}}>
										{chatData.length !== 0 && (<Chat chatData={chatData} />)}
										{chatData.length === 0 && (<div>No conversation was recorded</div>)}
									</div>
									
									<Divider />
										<div style={{fontSize: 18}}><span className="pointer" onClick={()=> handleView(`normalView`)} style={{color: "#1677ff"}}><b>Go Back</b></span></div>
									<Divider />
									
								</div>)}
								
								{driversView && (<div style={{fontSize: 18, fontFamily: "Lato"}}>
									
									<div style={{fontSize: 18}}><span style={{marginRight: 20, color: "green"}}>Green:</span><span>{`Pickup, Delivery & Assigned Driver Points.`}</span></div>
									<div style={{fontSize: 18}}><span style={{marginRight: 20, color: "black"}}>Black:</span><span>{`Unassigned Drivers Points.`}</span></div>
									
									<Divider />
									<div style={{fontSize: 18}}>
										{markers && markers.length > 0 && (<MapList
											markers={markers}
											height={"400px"}
											width={"100%"}
											searchCoordinate={searchCoordinate}
											title={`Scheduled Drivers`}
											handleSelect={handleSelect}
											selected={selected}
											ref={mapRef}
										/>)}
									</div>
																		
									{selected && selected === selectedOrder.courier && (<div><Divider /><div style={{fontSize: 18}}><span style={{marginRight: 20}}>Action:</span><span className="pointer" onClick={()=> unAssignDriver(selected, orderId)}>{`Unassign Offer ${selected}.`}</span></div></div>)}
									
									{selected && selected !== selectedOrder.courier && (<div><Divider /><div style={{fontSize: 18}}><span style={{marginRight: 20}}>Action:</span><span className="pointer" onClick={()=> assignDriver(selected, orderId)}>{`Assign Offer ${selected} ${isCourierAssigned(selected) ? ' - '+isCourierAssigned(selected) : ''}.`}</span></div></div>)}
									
									<Divider />
										<div style={{fontSize: 18}}><span className="pointer" onClick={()=> handleView(`normalView`)} style={{color: "#1677ff"}}><b>Go Back</b></span></div>
									<Divider />
									
								</div>)}
								
								{manageDriverView && (<div style={{fontSize: 18, fontFamily: "Lato"}}>
									
									<div style={{fontSize: 18}}><span style={{marginRight: 20, color: '#1677ff'}}>{`Increase distance if driver is having distance issue`}</span><span></span></div>
									<Divider />
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Store Pickup:</span><span className="pointer" onClick={()=> adjustDistance(orderId, 'pickup')}>{`Increase pickup distance`}</span></div>
									<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Customer Delivery:</span><span className="pointer" onClick={()=> adjustDistance(orderId, 'delivery')}>{`Increase delivery distance`}</span></div>
									
									<Divider />
										<div style={{fontSize: 18}}><span className="pointer" onClick={()=> handleView(`normalView`)} style={{color: "#1677ff"}}><b>Go Back</b></span></div>
									<Divider />
									
								</div>)}
								
								{orderNoteView && (<div style={{fontSize: 18, fontFamily: "Lato"}}>
									
									<div style={{fontSize: 18}}><span style={{marginRight: 20, color: '#1677ff'}}>{`Comment on Order #${orderId}`}</span><span></span></div>									
									<Divider />
									<div style={{height: 200, overflow: 'auto', padding: 10, backgroundColor: '#e5e5e5', marginBottom: 10, borderRadius: 5}} dangerouslySetInnerHTML={{__html: orderComment}}></div>
									<textarea id='orderNote' style={{height: 100, width: '100%', borderRadius: 5, borderWidth: 1, marginBottom: 10, padding: 5}} placeholder="Comment goes here"></textarea>
									<Button
										size={'large'}
										type={'primary'}
										onClick={()=> handleNote()}
										key={uuidv4()}
									>
										SUBMIT
									</Button>
									<Divider />
										<div style={{fontSize: 18}}><span className="pointer" onClick={()=> handleView(`normalView`)} style={{color: "#1677ff"}}><b>Go Back</b></span></div>
									<Divider />
									
								</div>)}
								
							</Drawer>	
						</div>
				</div>
			</div>   
		);
	}else{
		
		return null;
	}
	
	
};

export default LatestOrder;
