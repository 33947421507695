import React, {useEffect, useState} from "react";
import { Layout, Breadcrumb, Divider, message } from "antd";
import { AddNewStore } from "../../Components";
import { utils } from "../../Utils";
import { useNavigate } from "react-router-dom";

const AddNewStoreScreen = ({pcount, params, setUser, setHqId}) => {
	
	
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	const PROCESS_STORE = utils.PROCESS_STORE;
	const { Content } = Layout;
  
	const breadcrumbItems = [
		{ breadcrumbName: "Settings", title: "Settings" },
		{ breadcrumbName: "Add Store", title: "Add Store" },
	];
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("aduseremail");
		const storeId = localStorage.getItem("aduserstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "New Store | Restaurant | ErrandLocal";
	}, []);
	
	
	function onPostStore(dataPost) {
		if(!localStorage.getItem('__adel_token')){
			message.error('Invalid token');
			return false;
		}
		const token = localStorage.getItem('__adel_token');
		
		var ajax = {
			post : function(url, data, headers) {
				return new Promise(function(resolve, reject) {
					var xhr = new XMLHttpRequest();
					xhr.open('POST', url, true);

					xhr.onload = function() {
						if (xhr.status != 200) 
							return reject(`Response Status: ${xhr.status}\n${xhr.responseText}`);

						resolve(JSON.parse(xhr.responseText));
					}

					xhr.onerror = function() {
						reject('Failed to connect to remote server');
					}
					xhr.setRequestHeader('Authorization', token);
					xhr.send(JSON.stringify(data));
				})
			}
		}

		return ajax
			.post(PROCESS_STORE, dataPost, {
				'Authorization': token
			})
			
			.then(function(resp) {		
				let error;
				if(resp.data){
					error = resp.data;
					setUser(resp?.user);
					setHqId(resp?.hqId);
					message.info(`${resp.data}: You are in ${resp?.name}`);
					window.location.pathname = '/manage-store';
				}else if(resp.msg){
					error = resp.msg;
					message.error(error);
				}else {
					error = 'Unknown issue';
					message.error(error);
				}				
				//return resp;		
			})
	}
	
	
		
	
	return (
		<>
		<Content style={{ margin: "24px 16px 0", fontFamily: "Lato" }}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>New Store</h2>
			<span style={{ opacity: 0.5 }}>Add a new store.</span>
			<Divider />
			<AddNewStore pcount={pcount} params={params} onPostStore={onPostStore} />
		  </div>
		</Content>
		
	</>
	);
	
};

export default AddNewStoreScreen;

