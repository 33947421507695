import React, {useEffect, useState} from "react";
import { Layout, Breadcrumb, Divider, message } from "antd";
import { Home } from "../../Components";
import { useDispatch } from "react-redux";
import { utils } from "../../Utils";

const HomeScreen = ({pcount, initOrders, params, setUser, setHqId, loading}) => {
	
	initOrders = typeof initOrders === "object" ? initOrders : JSON.parse(initOrders);
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	const [updatecategory, setUpdateCategory] = useState(false);
	const [filelist, setFileList] = useState([]);
	const [storeParams, setStoreParams] = useState(params);
	const merchantCALL = utils.MERCHANT_CALL;
	const PROCESS_STORE = utils.PROCESS_STORE;
	
	const { Content } = Layout;
  
	const breadcrumbItems = [
		{ breadcrumbName: "Dashboard", title: "Dashboard" },
	];
	
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Dashboard | Restaurant | ErrandLocal";
	}, []);
  
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("aduseremail");
		const storeId = localStorage.getItem("aduserstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	useEffect(() => 
	{		
		if (localStorage.getItem('aduserstoreId')) {
			const interval = setTimeout(() => {
				setStoreParams(params);
			}, 100);
			return () => clearTimeout(interval); 
		}
	}, [params]);
	
	function onPostAction(dataPost) {
		if(!localStorage.getItem('__adel_token')){
			message.error('Invalid token');
			return false;
		}
		const token = localStorage.getItem('__adel_token');
		
		var ajax = {
			post : function(url, data, headers) {
				return new Promise(function(resolve, reject) {
					var xhr = new XMLHttpRequest();
					xhr.open('POST', url, true);

					xhr.onload = function() {
						if (xhr.status != 200) 
							return reject(`Response Status: ${xhr.status}\n${xhr.responseText}`);

						resolve(JSON.parse(xhr.responseText));
					}

					xhr.onerror = function() {
						reject('Failed to connect to remote server');
					}
					xhr.setRequestHeader('Authorization', token);
					xhr.send(JSON.stringify(data));
				})
			}
		}		

		return ajax
			.post(PROCESS_STORE, dataPost, {
				'Authorization': token
			})			
			.then(function(resp) {
				let error;
				if(resp.data){
					error = resp.data;
					message.info(`${resp.data}`);
				}else if(resp.msg){
					error = resp.msg;
					message.error(error);
				}else {
					error = 'Unknown issue';
					message.error(error);
				}				
				//return resp;		
			})
	}
	
	
	return (
		<>
		<Content style={{ margin: "24px 16px 0", fontFamily: "Lato"}}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>Welcome!</h2>
			<span style={{ opacity: 0.5 }}>Manage your restaurant.</span>
			<Divider />
			<Home pcount={pcount} initOrders={initOrders} params={storeParams} setUser={setUser} setHqId={setHqId} onPostAction={onPostAction} loading={loading}/>
		  </div>
		</Content>
		
	</>
	);
	
};

export default HomeScreen;
