import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { Counter } from "../../Components";
import { utils } from "../../Utils";

const ChangePasswordMain = ({onPostChangePassword}) => {
		
	const [oldPass, setOldPass] = useState('');
	const [newPass, setNewPass] = useState('');	
	const [newPass2, setNewPass2] = useState('');	
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');	
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("aduseremail");
		const storeId = localStorage.getItem("aduserstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);

	
	const onSubmitChangePassword = (e) => 
	{
		e.preventDefault();
		let oldPass, newPass, newPass2, output, msg;
		
		oldPass = document.getElementById('oldPass').value;
		newPass = document.getElementById('newPass').value;
		newPass2 = document.getElementById('newPass2').value;
		document.getElementById('feedback').innerHTML = "";
		
		if(!oldPass) {
			msg = 'Enter current password';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!newPass) {
			msg = 'Enter new password';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!newPass2) {
			msg = 'Enter new password again';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}
		if (newPass !== newPass2) {
			msg = 'New password does not match';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}		
		
		output = {
			'rtype': 'changepass',
			'storeId': storeId,
			'oldPass': oldPass,		
			'newPass': newPass,
			'newPass2': newPass2,
			'email': email,
		}
		onPostChangePassword(output);	
	}
	
	return (
		<>			
			<div className="mb-4">
				<label htmlFor="oldPass" className="form-label">
					<b>Current Password</b> *
				</label>
				<input className="form-control py-3 tags-editor" type="password" name="oldPass" id="oldPass" placeholder="Current Password" title="Current Password" value={oldPass} onChange={(e) => setOldPass(e.target.value)} autoComplete="off" />
			</div>
			
			<div className="mb-4">
				<label htmlFor="newPass" className="form-label">
					<b>New Password</b> *
				</label>
				<input className="form-control py-3 tags-editor" type="password" name="newPass" id="newPass" placeholder="New Password" title="New Password" value={newPass} onChange={(e) => setNewPass(e.target.value)} />
			</div>
			
			<div className="mb-4">
				<label htmlFor="newPass" className="form-label">
					<b>New Password Again</b> *
				</label>
				<input className="form-control py-3 tags-editor" type="password" name="newPass2" id="newPass2" placeholder="New Password Again" title="New Password Again" value={newPass2} onChange={(e) => setNewPass2(e.target.value)} />
			</div>
					
			<div className="incsizespan center mb-2" id="feedback">
				
			</div>
			<div className="d-grid">
				<button id="updateProfile" className="btn btn-primary py-3" onClick={onSubmitChangePassword}>
					Update Now
				</button>
			</div>
		</>
	)
 
};

const ChangePassword = ({pcount, onPostChangePassword}) => {
	const navigate = useNavigate();

	return (
		<section className="content-main">
			<div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
				<div className="content-header1">			
					<div><div onClick={()=> navigate("/")} className="btn btn-primary">Back to dashboard</div></div>
				</div>				
				<Counter pcount={pcount} />
			</div>
			<div className="card shadow-sm">
				<div className="card-body">
					<div className="row">
						<div className="col-md-12 col-lg-4">
							<ChangePasswordMain onPostChangePassword={onPostChangePassword} />
						</div>						
						<div className="col-md-12 col-lg-8">
						
						</div>
					</div>
				</div>
			</div>
		</section>
	)

}

export default ChangePassword;
