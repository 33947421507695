import React, {useEffect, useState} from "react";
import { Layout, Breadcrumb, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { Orders } from "../../Components";
import { useDispatch } from "react-redux";
import { setOrders } from "../../Includes/NavSlice";
import { utils } from "../../Utils";

const OrderScreen = ({pcount, initOrders}) => 
{
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	const { Content } = Layout;
  
	const breadcrumbItems = [
		{ breadcrumbName: "Orders", title: "Orders" },
		{ breadcrumbName: "All Orders", title: "All Orders" },
	];
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("aduseremail");
		const storeId = localStorage.getItem("aduserstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Order List | Restaurant | ErrandLocal";
	}, []);
	
		
	  return (
		<Content style={{ margin: "24px 16px 0", fontFamily: "Lato" }}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>All Orders</h2>
			<span style={{ opacity: 0.5 }}>List of all order received to your store</span>
			<Divider />
			{initOrders.length > 0 && (<Orders pcount={pcount} initOrders={initOrders} />)}
		  </div>
		</Content>
	  );

	
};

export default OrderScreen;
