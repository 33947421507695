import React, {useEffect, useState} from "react";
import { Layout, Breadcrumb, Divider, message } from "antd";
import { AddStoreLocation } from "../../Components";
import { utils } from "../../Utils";
import { useNavigate } from "react-router-dom";

const AddStoreLocationScreen = ({pcount, update2, setUpdate2, params}) => {
	
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	const { Content } = Layout;
  
	const breadcrumbItems = [
		{ breadcrumbName: "Settings", title: "Settings" },
		{ breadcrumbName: "Manage Store", title: "Manage Store" },
		{ breadcrumbName: "Add New Location", title: "Add New Location" },
	];
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("aduseremail");
		const storeId = localStorage.getItem("aduserstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "New Location | Restaurant | ErrandLocal";
	}, []);
	
	
		
	const onPostStore = (param) =>
	{	
		let targeturl = utils.MERCHANT_URL,
		token = localStorage.getItem('__adel_token'),
		formdata = new FormData();
				
		Object.entries(param).forEach(entry => {
			let [key, value] = entry;
			formdata.append(key, value);		
		});
		
		let ajax = new XMLHttpRequest();
		ajax.upload.addEventListener("progress", progressHandler, false); 
		ajax.addEventListener("load", completeHandler, false); 
		ajax.addEventListener("error", errorHandler, false); 
		ajax.addEventListener("abort", abortHandler, false); 
		ajax.open("POST", targeturl); 
		ajax.setRequestHeader('Authorization', 'Bearer '+token);
		ajax.send(formdata);
		return false;
	}
	
	function progressHandler(event){ 
        //message.info("Uploaded "+event.loaded+" bytes of "+event.total);
		let percent = (event.loaded / event.total) * 100;
		//message.info(Math.round(percent)+"% uploaded... please wait");
	} 
	async function completeHandler(event){	
		const data = JSON.parse(event.target.response);	
		if(data.msg === 'Successful' || data.msg === 'Success'){
			message.success('Location was created, Please update the store information');
			await setUpdate2(!update2);			
			setTimeout(()=>{				
				navigate('/manage-store', {replace: true});
			}, 1000);
		}else if(data.msg === 'Token Updated'){
			message.info('Please republish');
			localStorage.setItem('__adel_token', data.token);
		}else{
			message.error(data.msg);			
		}
	} 
	function errorHandler(event){ 
		message.error('Upload Failed');
	} 
	function abortHandler(event){ 
		message.error('Upload Aborted');
	} 
	
	return (
		<>
		<Content style={{ margin: "24px 16px 0", fontFamily: "Lato" }}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>New Location</h2>
			<span style={{ opacity: 0.5 }}>Add a new location for your business.</span>
			<Divider />
			<AddStoreLocation pcount={pcount} onPostStore={onPostStore} params={params} />
		  </div>
		</Content>
		
	</>
	);
	
};

export default AddStoreLocationScreen;

