import React, {useEffect, useState, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { createTheme } from 'react-data-table-component';
import { useSelector, useDispatch } from "react-redux";
import { Counter } from "../../Components";
import { utils } from "../../Utils";
import { v4 as uuidv4 } from "uuid";
import { setDrivers } from "../../Includes/NavSlice";
import { List, Button, Input, Divider, message, Drawer } from 'antd';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<>
		<input
			id="search"
			type="text"
			placeholder="Filter By Start point, Status, Driver Id, Country, Email, Phone or Name..."
			className="form-control p-2 tags-editor"
			value={filterText}
			onChange={onFilter}
		/>
	</>
);

const Export = ({ onExport }) => <div><div onClick={e => onExport(e.target.value)} className="btn btn-primary">Export</div></div>;

const Drivers = ({pcount, users, onPostAction}) => {
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [selected, setSelected] = useState("");
	const [item, setItem] = useState({});
	const [orderComment, setOrderComment] = useState("");
	const [adminStoresVisible, setAdminStoresVisible] = useState(false);
	const [normalView, setNormalView] = useState(true);	
	const [notificationView, setNotificationView] = useState(false);
	const [smsView, setSMSView] = useState(false);
	const [idStatus, setIdStatus] = useState("");
	const logemail = localStorage.getItem('adloguseremail');
	const getuser = utils.getUser(logemail);
	
	const [nusers, setNUsers] = useState([]);
	users = typeof users === "object" ? users : JSON.parse(users);
	
	useEffect(() =>
	{
		const interval = setTimeout(() => {
			let nusers = [];
			if(users[0]?.id){
				dispatch(setDrivers(users));			
				nusers = users.map((user, i) => {
					let index = i+1;
					let name = user.first_name+' '+user.last_name;
					let country = '';
					if(user?.country){
						country = user?.country === 'US' ? 'United States' : 'Canada';
					}
					return { ...user, index, name, country };
				});
				setNUsers(nusers);
			}
		}, 100);
		return () => clearTimeout(interval); 
	  
	}, [users]);
	
	
	const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	
	const filteredItems = nusers.filter(item => {
		// Filter by email
		const emailMatch = item.email && item.email.toLowerCase().includes(filterText.toLowerCase());

		// Filter by phone
		const phoneMatch = item.phone && item.phone.toLowerCase().includes(filterText.toLowerCase());
		
		// Filter by name
		const lastMatch = item.name && item.name.toLowerCase().includes(filterText.toLowerCase());
		
		
		// Filter by driver Id
		const lastId = item.id && item.id.toLowerCase().includes(filterText.toLowerCase());
		
		// Filter by start point
		const startPoint = item.start_point && item.start_point.toLowerCase().includes(filterText.toLowerCase());
		
		// Filter by country
		const userCountry = item.country && item.country.toLowerCase().includes(filterText.toLowerCase());
		
		// Filter by status
		const userStatus = item.status && item.status.toLowerCase().includes(filterText.toLowerCase());

		// Return true if either email or phone matches
		return emailMatch || phoneMatch || lastMatch || lastId || startPoint || userCountry || userStatus;
	});

	
	const handleView = async(view) => {
		if(view === 'normalView')
		{
			setNormalView(true);
			setNotificationView(false);
			setSMSView(false);
			setSelected('');
		}
		else if(view === 'smsView')
		{
			setNormalView(false);
			setNotificationView(false);
			setSMSView(true);
			setSelected('');
		}
		else if(view === 'notificationView')
		{
			setNormalView(false);
			setNotificationView(true);
			setSMSView(false);
			setSelected('');
		}
	};
	
	
	const handleInfo = async(orId, id) => {	
		const idItems = filteredItems.filter(item => {
			// Filter by id
			const idMatch = item.id && item.id === id;
			return idMatch;
		});
		setItem(idItems[0] ?? {});		
		setAdminStoresVisible(true);
		setNormalView(true);
		setNotificationView(false);
		setSMSView(false);
		setSelected('');
	};
	
	const handleHref = (target) => {
		navigate(target);
	};
	
	const handleSwitchModalCancel = () => {
		setAdminStoresVisible(false);
	};
	
	const handleSMS = async() => {
		const SMS = document.getElementById('smsText').value;
		if(!SMS){
			message.error('SMS text is required');
			return;
		}
		if(window.confirm('Are you sure you want to continue?'))
		{
			const output = {
				rtype : 'sendDriverSMS',
				itemId : item.id,
				email: item.email,
				recipient: item.phone,
				textMsg : SMS,
				logId : getuser.id,
			};		
			onPostAction(output);	
		}
	};
	
	const handleStatus = async() => {
		if(!idStatus){
			message.error('Please select status option');
			return;
		}
		if(window.confirm('Are you sure you want to continue?'))
		{
			const output = {
				rtype : 'updateDriverStatus',
				itemId : item.id,
				email: item.email,
				docVerify : idStatus,				
				logId : getuser.id,
			};		
			onPostAction(output);	
		}
	};
	
	const handleNotification = async() => {
		const notification = document.getElementById('notificationText').value;
		if(!notification){
			message.error('Notification text is required');
			return;
		}
		if(window.confirm('Are you sure you want to continue?'))
		{
			const output = {
				rtype : 'sendDriverNotification',
				itemId : item.id,
				email: item.email,
				recipient: item.pToken,
				textMsg : notification,
				logId : getuser.id,
			};
			onPostAction(output);	
		}
	};
	
	
	const confirmSwitchSession = () => {
	  const size = 'large';
	  				  
	  return [
		<Button
		  size={size}
		  onClick={handleSwitchModalCancel}
		  key={uuidv4()}
		>
		  CLOSE
		</Button>
	  ];
  };
		
	function convertArrayOfObjectsToCSV(array) {
		let result;

		const columnDelimiter = ',';
		const lineDelimiter = '\n';
		const keys = Object.keys(array[0]);

		result = '';
		result += keys.join(columnDelimiter);
		result += lineDelimiter;

		array.forEach(item => {
			let ctr = 0;
			keys.forEach(key => {
				if (ctr > 0) result += columnDelimiter;
				result += item[key];
				ctr++;
			});
			result += lineDelimiter;
		});

		return result;
	}
	// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
	function downloadCSV(array) {
		const link = document.createElement('a');
		let csv = convertArrayOfObjectsToCSV(array);
		if (csv == null) return;
		const filename = 'export.csv';
		if (!csv.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csv}`;
		}
		link.setAttribute('href', encodeURI(csv));
		link.setAttribute('download', filename);
		link.click();
	}
	
	const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(users)} />, []);

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};
		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);	
	
	createTheme('solarized', {
	  text: {
		primary: '#4fa607',
		secondary: '',
	  },
	  background: {
		default: '',
	  },
	  context: {
		background: '#cb4b16',
		text: '#cdd6d9',
	  },
	  divider: {
		default: '#4fa607',
	  },
	  action: {
		button: 'rgba(0,0,0,.54)',
		hover: 'rgba(0,0,0,.08)',
		disabled: 'rgba(0,0,0,.12)',
	  },
	}, 'light');
	
	
	const columns = [		
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>S/N</div>,
			selector: row => row.index,
			sortable: true,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '90px',
		},	
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>TIME</div>,
			selector: row => <span style={{color: '#001529', opacity: 0.7, fontWeight: 600, fontSize: 12}}>{utils.dateToString(row.join_timestamp)}</span>,
			sortable: true,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '180px',
		},
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>DRIVER ID</div>,
			selector: row => row.id,
			sortable: false,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '120px',
		},	
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>EMAIL</div>,
			selector: row => <span className="pointer" onClick={()=> handleInfo(`${row.email}`, `${row.id}`)} style={{color: "#1677ff"}}><b>{row.email}</b></span>,
			sortable: true,
			wrap: true,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '280px',
		},		
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>NAME</div>,
			selector: row => row.name,
			sortable: true,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '200px',
		},
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>STATUS</div>,
			selector: row => row.status,
			sortable: false,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '200px',
		},
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>START POINT</div>,
			selector: row => row.start_point,
			sortable: false,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
		},
	];
	
	return (
	<section className="content-main">
	  <div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
			<div className="content-header1">
				{actionsMemo}
			</div>		
			<Counter pcount={pcount} />
	  </div>
	  
		<div className="card mb-4 shadow-sm">
			<header className="card-header bg-white">
				<div className="row gx-3 py-3">
					<div className="col-lg-4 col-md-6 me-auto">				
					{subHeaderComponentMemo}              
					</div>
					<div className="col-lg-2 col-6 col-md-6">
						<select className="form-select tags-editor">
							<option>All</option>
						</select>
					</div>
				</div>
			</header>
      <div className="card-body">
			<div className="table-responsive" style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>
			<DataTable
				columns={columns}
				data={filteredItems}
				pagination
				paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
				//striped
				responsive
				theme="light"
				highlightOnHover
			/>
			
			<div className="order-comment">
				<Drawer
					style={{fontFamily: "Lato"}}
					title={`#${item.id} Details`}
					open={adminStoresVisible}
					footer={confirmSwitchSession()}
					maskClosable={false}
					closable={false}								
					placement="right"        
					onClose={handleSwitchModalCancel}
					size={`large`}
					//classNames={classNames}
					//styles={drawerStyles}
				>
					<Divider />
					{normalView && (<div style={{fontSize: 18, fontFamily: "Lato"}}>						
						<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Full Name:</span><span>{item.name}</span></div>
						<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Email:</span><span>{item.email}</span></div>
						<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Phone:</span><span>{item.phone}</span></div>
						<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Start Point:</span><span>{item.start_point}</span></div>
						<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Address:</span><span>{item.address}</span></div>
						<div style={{fontSize: 18}}><span style={{marginRight: 20}}>City:</span><span>{item.city}</span></div>
						<div style={{fontSize: 18}}><span style={{marginRight: 20}}>State/Province:</span><span>{item.state}</span></div>
						<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Zip/Postal Code:</span><span>{item.zip}</span></div>
						<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Country:</span><span>{item.country}</span></div>
						<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Date of Birth:</span><span>{item.date_of_birth}</span></div>
						<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Current Location:</span><span><a href={`https://www.google.com/maps/search/?api=1&query=${item.current_lat},${item.current_lng}`} target="_blank">View Map</a></span></div>
						{item.img && (<div style={{fontSize: 18}}><span style={{marginRight: 20}}>ID Link:</span><span><a href={`${item.img}`} target="_blank">View ID</a></span></div>)}
						<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Join Date:</span><span>{utils.dateToString(item.join_timestamp)}</span></div>
					  <div style={{fontSize: 18}}><span style={{marginRight: 20}}>Status:</span><span>{item?.status}</span></div>
						<div style={{fontSize: 18}}><span style={{marginRight: 20}}>Stores:</span><span className="pointer" onClick={()=> handleHref(`/nearby-stores/${item?.address} ${item?.city} ${item?.state}/${item?.country === 'Canada' ? 'CA' : 'US'}`)} style={{color: "#1677ff"}}>View Stores Nearby</span></div>
						
						<Divider />
						
						<div style={{fontSize: 18, alignItem: 'center', display: 'flex', justifyContent: 'space-between'}}>{item.phone && (<span className="pointer" onClick={()=> handleView(`smsView`)} style={{color: "#1677ff"}}>Send SMS</span>)} {item.pToken && (<span className="pointer" onClick={()=> handleView(`notificationView`)} style={{color: "#1677ff"}}>{`Send Notification`}</span>)}</div>
						
						{item.status !== 'NO UPLOAD' && (<div><Divider />
						
						<div style={{fontSize: 18, alignItem: 'center', display: 'flex', justifyContent: 'space-between'}}>
							<select
								onChange={(e) => setIdStatus(e.target.value)}
								style={{ height: 40, width: '50%', borderRadius: 5, borderWidth: 1, marginBottom: 10, padding: 5 }}
							>
								<option value="">{`Select status option`}</option>
								<option value="1">Deny</option>
								<option value="2">Re-upload</option>
								<option value="3">Approve</option>
							</select>
							<Button
								size={'large'}
								type={'primary'}
								onClick={() => handleStatus()}
								key={uuidv4()}
							>
								UPDATE
							</Button>
						</div></div>)}
						
						<Divider />				
					</div>)}
										
					{smsView && (
						<div style={{ fontSize: 18, fontFamily: "Lato" }}>
							<div style={{ fontSize: 18 }}>
								<span style={{ marginRight: 20, color: '#1677ff' }}>{`Send SMS to ${item?.email}`}</span>
								<span></span>
							</div>
							<Divider />
							<div style={{ fontSize: 18 }}>
								<span className="pointer" onClick={() => handleView(`normalView`)} style={{ color: "#1677ff" }}>
									<b>Go Back</b>
								</span>
							</div>
							<Divider />							
							<select
								onChange={(e) => document.getElementById('smsText').value = e.target.value}
								style={{ height: 40, width: '100%', borderRadius: 5, borderWidth: 1, marginBottom: 10, padding: 5 }}
							>
								<option value="">Select a predefined message</option>
								<option value="Please contact us if you have any issues.">Please contact us if you have any issues.</option>
								<option value="Update your account to start receiving order.">Update your account to start receiving order.</option>
								<option value="Get active, schedule and start earning.">Get active, schedule and start earning.</option>
							</select>
							<textarea
								id='smsText'
								style={{ height: 100, width: '100%', borderRadius: 5, borderWidth: 1, marginBottom: 10, padding: 5 }}
								placeholder="SMS goes here"
							></textarea>
							<Button
								size={'large'}
								type={'primary'}
								onClick={() => handleSMS()}
								key={uuidv4()}
							>
								SEND
							</Button>
						</div>
					)}

					
					{notificationView && (<div style={{fontSize: 18, fontFamily: "Lato"}}>						
						<div style={{fontSize: 18}}><span style={{marginRight: 20, color: '#1677ff'}}>{`Push Notification to ${item?.email}`}</span><span></span></div>									
						<Divider />
							<div style={{fontSize: 18}}><span className="pointer" onClick={()=> handleView(`normalView`)} style={{color: "#1677ff"}}><b>Go Back</b></span></div>
						<Divider />
						<select
							onChange={(e) => document.getElementById('notificationText').value = e.target.value}
							style={{ height: 40, width: '100%', borderRadius: 5, borderWidth: 1, marginBottom: 10, padding: 5 }}
						>
							<option value="">Select a predefined message</option>
							<option value="Please contact us if you have any issues.">Please contact us if you have any issues.</option>
							<option value="Update your account to start receiving order.">Update your account to start receiving order.</option>
							<option value="Get active, schedule and start earning.">Get active, schedule and start earning.</option>
						</select>
						<textarea id='notificationText' style={{height: 100, width: '100%', borderRadius: 5, borderWidth: 1, marginBottom: 10, padding: 5}} placeholder="Notification goes here"></textarea>
						<Button
							size={'large'}
							type={'primary'}
							onClick={()=> handleNotification()}
							key={uuidv4()}
						>
							SEND
						</Button>
								
					</div>)}
					
				</Drawer>	
			</div>
			</div>
      </div>
    </div>
	</section>
	);

};

export default Drivers;
