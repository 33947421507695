import React, {useEffect, useState, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import DataTable, { createTheme } from 'react-data-table-component';
import { useSelector, useDispatch } from "react-redux";
import { Counter } from "../../Components";
import { OrderStatus, setOrders } from "../../Includes/NavSlice";
import { utils } from "../../Utils";

const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<>
		<input
			id="search"
			type="text"
			placeholder="Filter By Order ID..."
			className="form-control p-2 tags-editor"
			value={filterText}
			onChange={onFilter}
		/>
	</>
);

const Export = ({ onExport }) => <div><div onClick={e => onExport(e.target.value)} className="btn btn-primary">Export</div></div>;

const renderOrderStatus = (orderStatus) => {
    const statusToColor = {
		[OrderStatus.NEW]: "alert-info accepted",
		[OrderStatus.COOKING]: "alert-warning warned",
		[OrderStatus.AT_STORE]: "alert-warning warned",
		[OrderStatus.COURIER_PICKED_UP]: "alert-warning warned",
		[OrderStatus.STORE_READY_FOR_PICKUP]: "alert-info accepted",
		[OrderStatus.COURIER_READY_FOR_PICKUP]: "alert-info accepted",
		[OrderStatus.STORE_COMPLETED]: "alert-success succeed",
		[OrderStatus.COURIER_COMPLETED]: "alert-success succeed",
		[OrderStatus.STORE_ACCEPTED]: "alert-info accepted",
		[OrderStatus.COURIER_ACCEPTED]: "alert-info accepted",
		[OrderStatus.STORE_DECLINED]: "alert-danger declined",
		[OrderStatus.COURIER_DECLINED]: "alert-danger declined",
		[OrderStatus.PAYMENT_DECLINED]: "alert-warning warned",
		[OrderStatus.CANCELLED]: "alert-danger declined",
		[OrderStatus.PENDING]: "alert-info accepted",
    };
	const el = statusToColor[OrderStatus[orderStatus]];

	//return <span className={`${el} orderstatus rounded-pill`}>{OrderStatus[orderStatus]}</span>;
	return OrderStatus[orderStatus];
};


const renderSettlementStatus = (SettlementStatus) => {
    const statusToColor = {
		"0": "alert-info accepted",
		"1": "alert-success succeed",
    };
	const statusToVal = {
		"0": "PENDING",
		"1": "COMPLETED",
    };
	const el = statusToColor[SettlementStatus];

	//return <span className={`${el} orderstatus rounded-pill`}>{statusToVal[SettlementStatus]}</span>;
	return statusToVal[SettlementStatus];
};

const renderOrderType = (stats) => {
    const statusToColor = {
		"Pickup": "alert-danger declined",
		"Delivery": "alert-warning warned",
    };
	const statusToVal = {
		"Pickup": "PICK UP",
		"Delivery": "DELIVERY",
    };
	const el = statusToColor[stats];

	//return <span className={`${el} orderstatus rounded-pill`}>{statusToVal[stats]}</span>;
	return statusToVal[stats];
};

const renderOrderAmount = (stats) => {   

	return <span style={{color: "black"}}><b>{stats}</b></span>;
};
	
const Orders = ({pcount, initOrders}) => {
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [orders, setInitOrders] = useState([]);
	initOrders = typeof initOrders === "object" ? initOrders : JSON.parse(initOrders);
	
	useEffect(() =>
	{		
		const interval = setTimeout(() => {
			let orders = [];
			if(initOrders[0]?.storeId){
				dispatch(setOrders(initOrders));			
				orders = initOrders.map((order, i) => {
					let index = i+1;
					return { ...order, index };
				});
				setInitOrders(orders);
			}
		}, 100);
		return () => clearTimeout(interval); 
	  
	}, [initOrders]);
	
	
	const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const filteredItems = orders.filter(
		item => item.orderId && item.orderId.toLowerCase().includes(filterText.toLowerCase()),
	);
		
	function convertArrayOfObjectsToCSV(array) {
		let result;

		const columnDelimiter = ',';
		const lineDelimiter = '\n';
		const keys = Object.keys(array[0]);

		result = '';
		result += keys.join(columnDelimiter);
		result += lineDelimiter;

		array.forEach(item => {
			let ctr = 0;
			keys.forEach(key => {
				if (ctr > 0) result += columnDelimiter;
				result += item[key];
				ctr++;
			});
			result += lineDelimiter;
		});

		return result;
	}
	// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
	function downloadCSV(array) {
		const link = document.createElement('a');
		let csv = convertArrayOfObjectsToCSV(array);
		if (csv == null) return;
		const filename = 'export.csv';
		if (!csv.match(/^data:text\/csv/i)) {
			csv = `data:text/csv;charset=utf-8,${csv}`;
		}
		link.setAttribute('href', encodeURI(csv));
		link.setAttribute('download', filename);
		link.click();
	}
	
	const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(initOrders)} />, []);

	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};
		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);	
	
	createTheme('solarized', {
	  text: {
		primary: '#4fa607',
		secondary: '',
	  },
	  background: {
		default: '',
	  },
	  context: {
		background: '#cb4b16',
		text: '#cdd6d9',
	  },
	  divider: {
		default: '#4fa607',
	  },
	  action: {
		button: 'rgba(0,0,0,.54)',
		hover: 'rgba(0,0,0,.08)',
		disabled: 'rgba(0,0,0,.12)',
	  },
	}, 'light');
	
	
	const columns = [		
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>S/N</div>,
			selector: row => row.index,
			sortable: true,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '90px',
		},	
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>TIME</div>,
			selector: row => <span style={{color: '#001529', opacity: 0.7, fontWeight: 600, fontSize: 12}}>{utils.dateToString(row.createdTimestamp)}</span>,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '200px',
		},
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>ORDER ID</div>,
			selector: row => row.orderId,
			sortable: false,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '150px',
		},	
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>AMOUNT</div>,
			selector: row => renderOrderAmount('$'+parseFloat((parseFloat(row.subtotal) / 100) + (parseFloat(row.salestax) / 100)).toFixed(2)),
			sortable: true,
			wrap: true,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '100px',
		},		
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>ORDER STATUS</div>,
			selector: row => renderOrderStatus(row.storestatus),
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '200px',
		},		
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>SETTLEMENT STATUS</div>,
			selector: row => renderSettlementStatus(row.settlement),
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
			width: '200px',
		},
		{
			name: <div style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>ORDER TYPE</div>,
			selector: row => renderOrderType(row.deliveryType),
			sortable: true,
			style: {
				fontSize: '14px',
				fontWeight: 500,
			},
		},	
		{
			cell: (row) => <span onClick={()=> navigate(`/order/${row.orderId}`)} style={{cursor: 'pointer'}} className="text-success"><i className="fas fa-eye"></i></span>,	
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
			right: true,
		},
	];
	
	return (
	<section className="content-main">
	  <div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
		<div className="content-header1">
			{actionsMemo}
        </div>
		
		<Counter pcount={pcount} />
	  </div>
	  
      <div className="card mb-4 shadow-sm">
        <header className="card-header bg-white">
          <div className="row gx-3 py-3">
            <div className="col-lg-4 col-md-6 me-auto">				
				{subHeaderComponentMemo}              
            </div>
            <div className="col-lg-2 col-6 col-md-6">
              <select className="form-select tags-editor">
                <option>All</option>
              </select>
            </div>
          </div>
        </header>
        <div className="card-body">
			<div className="table-responsive" style={{fontSize: 14, fontWeight: 600, fontFamily: 'Lato'}}>
			<DataTable
				columns={columns}
				data={filteredItems}
				pagination
				paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
				//striped
				responsive
				theme="light"
				highlightOnHover
			/>
			</div>
        </div>
      </div>
    </section>
	);
};

export default Orders;
