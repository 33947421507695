import React from "react";
import { useNavigate } from 'react-router-dom';
import { utils } from "../../Utils";
import { Button } from 'antd';

const renderOrderAmount = (stats) => {   

	return <span className="h5" style={{color: "#0ecb81"}}><b>{stats}</b></span>;
};

const Item = ({ product, onPostDelete, onPostAction }) => {
	
	const navigate = useNavigate();
	const storeId = localStorage.getItem('aduserstoreId');
	const email = localStorage.getItem('aduseremail');
	let logemail = localStorage.getItem('adloguseremail');
	logemail = logemail ?? '';
  
	const deleteItem = (id) => {
		if (window.confirm('Are you sure you want to delete?')) {
			// Delete it!		  
			var output = {
				'rtype': 'deleteitem',
				'email': email,
				'logemail': logemail,
				'storeId': storeId,
				'itemId': id,
			}
			onPostDelete(output);		  
		}
	}
	
	const actionCmd = (action, id) => {
		// Delete it!		  
		var output = {
			'rtype': 'displayitem',
			'email': email,
			'logemail': logemail,
			'storeId': storeId,
			'itemId': id,
			'action': action,
		}
		onPostAction(output);		
	}

	return (
    <>
      <div className="col-md-6 col-sm-6 col-lg-3 mb-5">
        <div className="card card-product-grid shadow-sm">
          <div onClick={()=> navigate(`/edit-item/${product?.itemId}`, {replace: true })} className="img-wrap" style={{cursor: 'pointer'}}>
            <img src={product?.avatar} alt="Product" />
          </div>
          <div className="info-wrap">
            <div onClick={()=> navigate(`/edit-item/${product?.itemId}`, { replace: true })} className="title text-truncate">
              {renderOrderAmount(product?.name)}
            </div>
            <div className="price mb-2">{`$${(parseFloat(product?.price) / 100).toFixed(2)} - ${product.itemcategoryname}`}</div>
            <div className="mb-2">- {product?.itempackage}</div>
            <div className="price mb-2">Status: {product?.itemStatus === '0' ? (<font color="red">Not Listed</font>) : (<font color="green">Listed</font>)}</div>
            <div className="price" style={{marginBottom: 20, cursor: 'pointer'}}>Action: {product?.itemStatus === '0' ? (<span onClick={()=> actionCmd('1', product?.itemId)}><font color="gray"><u>Show</u></font></span>) : (<span onClick={()=> actionCmd('0', product?.itemId)}><font color="gray"><u>Hide</u></font></span>)}</div>
            <div className="price mb-2"></div>
			
						<div className="order-actions">
						<Button type="primary" className="" onClick={()=> navigate(`/edit-item/${product?.itemId}`, {replace: true})} style={{width: '40%', height: '40px'}}>
						<b><a href={`/edit-item/${product?.itemId}`} style={{color: '#fff'}}>EDIT</a></b>
						</Button>
						<Button onClick={() => deleteItem(product?.itemId)} style={{width: '40%', height: '40px'}} danger>
						<b>DELETE</b>
						</Button>							  
						</div>            
			
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
