import React, {useState, useEffect, useMemo} from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import DataTable, { createTheme } from 'react-data-table-component';
import { selectStores } from "../../Includes/NavSlice";
import { utils } from "../../Utils";


const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<>
		<input
			id="search"
			type="text"
			placeholder="Filter By Category Name..."
			className="form-control p-2 tags-editor"
			value={filterText}
			onChange={onFilter}
		/>
	</>
);

const CategoriesTable = ({update, setUpdate, onPostDeleteCategory}) => {
	
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	const [stores, setStores] = useState([]);
	
	const storeURL = utils.MERCHANT_CALL;
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("aduseremail");
		const storeId = localStorage.getItem("aduserstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	useEffect(() => {
	  if (storeId && storeId !== 'undefined') {
		const interval = setTimeout(() => {
		  fetchItemCategories(storeId);
		}, 100);
		return () => clearTimeout(interval); 
	  }
	}, [storeId]);
	
	useEffect(() => {
	  if (update && storeId && storeId !== 'undefined') {
		const interval = setTimeout(() => {
		  fetchItemCategories(storeId);		  
		}, 100);
		return () => clearTimeout(interval); 
	  }
	}, [storeId, update]);
  
  
	const fetchItemCategories = async() => 
	{
		const header = await utils.genHeader();
		const target = await storeURL+'?request=store&category='+storeId;
		const res = await utils.fetchURL(target, 'GET', '', header);	
		const data = await res.json();
		let rstores = [];
		let index = 0;
		Object.entries(data).forEach(entry => {
			const i = index++;
			const [key, val] = entry;
			const len = val.length;
			const ncat = val?.name;
			rstores.push({id: val?.id, name: ncat, data: [], index: i});
			if(i === data.length - 1){
				setStores(rstores);
				setUpdate(false);
			}
		});
	}

	
	
	createTheme('solarized', {
	  text: {
		primary: '#4fa607',
		secondary: '',
	  },
	  background: {
		default: '',
	  },
	  context: {
		background: '#cb4b16',
		text: '#cdd6d9',
	  },
	  divider: {
		default: '#4fa607',
	  },
	  action: {
		button: 'rgba(0,0,0,.54)',
		hover: 'rgba(0,0,0,.08)',
		disabled: 'rgba(0,0,0,.12)',
	  },
	}, 'light');
	
	const columns = [
		{
			name: <div style={{fontSize: 17, fontWeight: 500, color: '#000000'}}>SAVED CATEGORIES</div>,
			selector: row => row.name,
			sortable: false,
			maxwidth: '800px',
			wrap: true,
			style: {
				fontSize: '16px',
				fontWeight: 500,
			},
		},	
		{
			cell: (row) => <div className="dropdown"><Link to="#" data-bs-toggle="dropdown" className="btn btn-light"><i className="fas fa-ellipsis-h"></i></Link><div className="dropdown-menu"><Link className="dropdown-item text-danger" to="#" onClick={()=>handleDeleteClick(row.id)}>Delete</Link></div></div>,
			width: '70px',
			right: 0,
		},
	];
		
	const handleDeleteClick = (categoryId) => {	
		if(window.confirm('Items in this category will also be deleted')){
			var output = {
				'rtype': 'deletecategory',
				'categoryId': categoryId,
				'email': email,
				'storeId': storeId,
			};
			onPostDeleteCategory(output);
		}
	};		
	
	const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const filteredItems = stores.filter(
		item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
	);
	
	
	const subHeaderComponentMemo = useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);
	
	return (
    <div className="col-md-12 col-lg-8">
		<header className="card-header bg-white">
          <div className="row gx-3 py-3">
            <div className="col-lg-4 col-md-6 me-auto">				
				
            </div>
			<div className="col-lg-4 col-6 col-md-6">
              {subHeaderComponentMemo} 
            </div>
          </div>
        </header>
		<DataTable
            columns={columns}
            data={stores}
			pagination
			paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
			striped
			responsive
			theme="solarized"
        />
		
	</div>
  );
};

export default CategoriesTable;
