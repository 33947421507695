import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Menu } from "antd";
import { v4 as uuidv4 } from "uuid";
import { utils } from "../../Utils";
import {
	BarsOutlined, 
	PlusOutlined, 
	PlusSquareOutlined, 
	MinusOutlined, 
	MinusSquareOutlined, 
	BorderBottomOutlined,
	BorderTopOutlined,
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	DesktopOutlined,
	MessageOutlined,
	PieChartOutlined,
	} from "@ant-design/icons";


const SideBar = ({params}) => {
  const navigate = useNavigate();
	const [userRole, setUserRole] = useState(null);
	const [displayResume, setDisplayResume] = useState(false);
	const email = localStorage.getItem('aduseremail');
	
	let items;
  
  const { Sider } = Layout;
  
  const getItem = (label, key, icon, children, onClick) => {
		return {
			key,
			icon,
			children,
			label,
			onClick
		};
	};
	
	useEffect(() => 
	{		
		let loguser = '';
		if(localStorage.getItem('adloguseremail')){
			loguser = localStorage.getItem('adloguseremail');
		}
		const getUser = utils.getUser(loguser);
		if(getUser !== null){
			setUserRole(getUser);
		}
		
	}, []);
	
	useEffect(() => 
	{		
		const interval = setInterval(() => {
			const now = new Date();
			const timestamp = now.getTime();
			const localTimestamp = now.toLocaleTimeString();
			const nowStamp = parseFloat(timestamp / 1000);
			
			const locations = params?.locations;
			if(locations){
				for(let i=0; i<locations?.length; i++)
				{					
					const lastUpdated = locations[i]?.lastUpdated;
					const storeSwitch = locations[i]?.storeSwitch;
					const diff = nowStamp - lastUpdated;
					//console.log(nowStamp, lastUpdated, storeSwitch, diff);
					if(storeSwitch === '0' && diff >= 1800){
						setDisplayResume(true);
						i = locations?.length;
					}else{
						setDisplayResume(false);
					}
				}
			}
		}, 1000);
		return () => clearInterval(interval);
				
	}, [params]);
	
	const makeExit = () =>
	{
		if(window.confirm(`Do you still want to logout\n\n${userRole?.user}?`)){
			localStorage.clear();
			window.location.assign("/");  
			return false;
		}
	}
	
	const pageReload = () =>
	{
		window.location.reload(); 	
	}
	
	
	if(userRole?.role === 'Level2')
	{
		items = [
			getItem('Dashboard', 'dashboard', <DesktopOutlined />, null, () => {    navigate('/', { replace: true })}),
			getItem('Listing Request', 'listing-request', <BarsOutlined />, null, () => {    navigate('/listings', { replace: true })}),			
			getItem('Nearby Stores', 'nearby-stores', <PieChartOutlined />, null, () => {    navigate('/nearby-stores', { replace: true })}),
			getItem('Orders', 'orders', <BarsOutlined />, [
				getItem('New', 'orders-new', null, null, () => {navigate('/new-orders', { replace: true })}), 
				getItem('All Orders', 'orders-all', null, null, () => {navigate('/orders', { replace: true })})
			]),
			getItem('Menu', 'menu', <BarsOutlined />, [
				getItem('List', 'menu-list', null, null, () => {navigate('/item-list', { replace: true })}),
				getItem('Create', 'menu-create', null, null, () => {navigate('/add-item', { replace: true })}),
			]),
			getItem('Categories', 'categories', <PieChartOutlined />, null, () => {    navigate('/create-category', { replace: true })}),
			getItem('Settings', 'settings', <BarsOutlined />, [			
				getItem('Add Store', 'add-store', null, null, () => {navigate('/add-store', { replace: true })}),
				getItem('Manage Store', 'manage-store', null, null, () => {navigate('/manage-store', { replace: true })}), 
				getItem('Change Password', 'change-password', null, null, () => {navigate('/change-password', { replace: true })}),
				//getItem('Billing', uuidv4(), null, null, () => {navigate('/billing', { replace: true })}),
			]),
			getItem('Users', 'users', <PieChartOutlined />, null, () => {    navigate('/user-list', { replace: true })}),
			getItem('Drivers', 'drivers', <BarsOutlined />, [
				getItem('List', 'drivers-list', null, null, () => {navigate('/driver-list', { replace: true })}),
				getItem('Settlement', 'driver-settlement', null, null, () => {navigate('/driver-settlement', { replace: true })}),
			]),
			getItem('', 'default', null, null, () => {
				return	
			}),
		];
	}
	else if(userRole?.role === 'Level1')
	{
		items = [
			getItem('Dashboard', 'dashboard', <DesktopOutlined />, null, () => {    navigate('/', { replace: true })}),
			getItem('Listing Request', 'listing-request', <BarsOutlined />, null, () => {    navigate('/listings', { replace: true })}),
			getItem('Nearby Stores', 'nearby-stores', <PieChartOutlined />, null, () => {    navigate('/nearby-stores', { replace: true })}),
			getItem('Menu', 'menu', <BarsOutlined />, [
				getItem('List', 'menu-list', null, null, () => {navigate('/item-list', { replace: true })}),
				getItem('Create', 'menu-create', null, null, () => {navigate('/add-item', { replace: true })}),
			]),
			getItem('Settings', 'settings', <BarsOutlined />, [
				getItem('Add Store', 'add-store', null, null, () => {navigate('/add-store', { replace: true })}),
				getItem('Manage Store', 'manage-store', null, null, () => {navigate('/manage-store', { replace: true })}),
			]),
			getItem('', 'default', null, null, () => {
				return
			}),
		];
	}
	else
	{
		items = [
			getItem('Dashboard', 'dashboard', <DesktopOutlined />, null, () => {    navigate('/', { replace: true })}),
			getItem('Listing Request', 'listing-request', <BarsOutlined />, null, () => {    navigate('/listings', { replace: true })}),
			getItem('Nearby Stores', 'nearby-stores', <PieChartOutlined />, null, () => {    navigate('/nearby-stores', { replace: true })}),
			getItem('Menu', 'menu', <BarsOutlined />, [
				getItem('List', 'menu-list', null, null, () => {navigate('/item-list', { replace: true })}),
				getItem('Create', 'menu-create', null, null, () => {navigate('/add-item', { replace: true })}),
			]),
			getItem('Settings', 'settings', <BarsOutlined />, [
				getItem('Add Store', 'add-store', null, null, () => {navigate('/add-store', { replace: true })}),
				getItem('Manage Store', 'manage-store', null, null, () => {navigate('/manage-store', { replace: true })}),
			]),
			getItem('', 'default', null, null, () => {
				return
			}),
		];
	}
	
	function getAction(){
		if(displayResume){
			return (<><div className="content-header1">			
				<div style={{alignItems: 'center', justifyContent: 'center', display: 'flex', color: '#ffffff'}}>Enable Paused Locations</div>
			</div><br/></>);
		}else{
			return null;
		}
		
		/*<div className="content-header1">			
			<div style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}><div onClick={()=> ''} className="btn btn-info" style={{color: '#ffffff'}}>Hide All Menu</div></div>
		</div>
		<br/>	*/
	}
	

  return (
    <>
      <Sider 
		//collapsible 
		//collapsed={collapsed} 
		//onCollapse={(value) => setCollapsed(value)}
		style={{					
			//overflow: 'auto',
			//height: '100vh',
			//position: 'fixed',
			//left: 0,
			//top: 0,
			//bottom: 0,
			//zIndex: 500,
			
		}}
		breakpoint="lg"
		collapsedWidth="0"
		onBreakpoint={(broken) => {
			//console.log(broken);
		}}
		onCollapse={(collapsed, type) => {
			//console.log(collapsed, type);
		}}
	>
		<div className="logo">
			<img 
			src={require('../../logo.png')} 
			style={{
				position: 'absolute',
				width: 'auto',
				height: 70,
				borderRadius: 35,
			}}
			alt={`Logo`}
			/>
		</div>
		
		<Menu 
			theme="dark" 
			defaultSelectedKeys={['4']} 
			mode="inline" 
			items={items}
			onClick={(item) => {
				if (item.onClick) {
				  item.onClick();
				}
			}}
		/>
		{getAction()}
				
		<div className="content-header1">			
			<div style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}><div onClick={()=> pageReload()} className="btn btn-info" style={{color: '#ffffff'}}>Refresh Page</div></div>
		</div>
		<br/>
		<div className="content-header1">			
			<div style={{alignItems: 'center', justifyContent: 'center', display: 'flex'}}><div onClick={()=> makeExit()} className="btn btn-danger" style={{color: '#ffffff'}}>Log Out</div></div>
		</div>
		
		
	</Sider>
    </>
  );
};

export default SideBar;