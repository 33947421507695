import React, {useEffect, useState, useMemo} from "react";
import { Layout, Breadcrumb, Divider, message } from "antd";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { utils } from "../../Utils";
import { Counter } from "../../Components";
import { useSelector } from "react-redux";
import DataTable, { createTheme } from 'react-data-table-component';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<>
		<input
		id="search"
		type="text"
		placeholder="Filter By Store Name or ID..."
		className="form-control p-2 tags-editor"
		value={filterText}
		onChange={onFilter}
		/>
	</>
);

const NearbyStoreScreen = ({params, setUser, setHqId, setNewLoc, pcount}) => {
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const data = useParams();
	
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	const [stores, setStores] = useState([]);
	const [update, setUpdate] = useState(false);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [filterText, setFilterText] = useState('');
	const [searchAddress, setSearchAddress] = useState('');
	const [searchCountry, setSearchCountry] = useState('');
	const [updatecategory, setUpdateCategory] = useState(false);
		
	const merchantURL = utils.PROCESS_STORE;
	const userCALL = utils.USER_CALL;
	
	
	const { Content } = Layout;
  
	const breadcrumbItems = [
		{ breadcrumbName: "Nearby Stores", title: "Nearby Stores" },
	];
	
	useEffect(() => 
		{	
			const email = localStorage.getItem("aduseremail");
			const storeId = localStorage.getItem("aduserstoreId");
			setEmail(email ?? '');
			setStoreId(storeId ?? '');
			// eslint-disable-next-line
		}, []);
		
		useEffect(() => {
			window.document.body.style.overflow = "auto";
			document.title = "Nearby Stores | Restaurant | ErrandLocal";
		}, []);
		
		
		useEffect(() => {
			if (email && email !== 'undefined') {
				if(data?.address){
					const {address, countryCode} = data;
					//console.log(address, countryCode);
					const interval = setTimeout(() => {						
						fetchStores(address, countryCode);
					}, 100);
					return () => clearTimeout(interval); 
				}else if(localStorage.getItem('nearbystore') && !data?.address){
					const localDataString = localStorage.getItem('nearbystore');
					const localData = JSON.parse(localDataString);
					if(localData?.address){
						//console.log('THREE');
						setSearchAddress(localData.address);
						setSearchCountry(localData.country);
						setStores(localData.data);
					}
				}
			}
		}, [email, data]);
		
		const filteredItems = stores.filter(item => {
			// Filter by email
			const hqIdMatch = item.hqId && item.hqId.toLowerCase().includes(filterText.toLowerCase());
			
			// Filter by name
			const nameMatch = item.name && item.name.toLowerCase().includes(filterText.toLowerCase());
			
			// Return true if either email or phone matches
			return hqIdMatch || nameMatch;
		});

		
		const fetchStores = async (saddress, countryCode) => 
		{
			try{
				//request=storelist&lat=46.1109725&lng=-64.8105188&country=CA
				const targetURL = await userCALL+'?request=storelist&address='+saddress+'&country='+countryCode+'&fetch=all';
				
				const header = await utils.genHeader();
				const res = await fetch(targetURL);
				const result = await res.json();
				const data = result?.data?.storeInfo ?? [];
				
				let rstores = [];
				let index = 0;
				if(typeof data === 'object' && data.length > 0)
				{
					Object.entries(data).forEach(entry => {
						const i = index++;
						const [key, val] = entry;
						let orderSwitch = val.switch;
						let features = val.features;
						let fcity = val.city;
						if(!fcity){
							message.error(`${val.name} city is null`);
						}
						let fname = val.name;
						let fhqId = val.hqId;
						let fcountry = val.country;
						if(fcountry === countryCode){
						
							const {id, hqId, name, address, city, state, zip} = val;								
							const navStore = utils.convertAddress(name);
							const navCity = utils.convertAddress(city);
							const navId = utils.convertAddress(id);
							const navZip = utils.convertAddress(zip);
							const navState = utils.convertAddress(state);
							const navLink = `/${navState}/${navCity}/${navZip}/${navStore}/${navId}`;
							const editLink = `/manage-store/${navId}`;
							
							orderSwitch = orderSwitch === '1' ? 'RESUMED' : 'PAUSED';
							features = features === '2' ? 'DISPLAYED' : 'HIDDEN';
							
							rstores.push({id, hqId: fhqId, name: fname, address, city, state, zip, features, orderSwitch, navLink, editLink, index: i});
							
							if(i === data.length - 1){
								setSearchAddress(saddress);
								setSearchCountry(countryCode);
								setStores(rstores);
								setUpdate(false);
								const localData = {
									address: saddress,
									country: countryCode,
									data: rstores
								};
								localStorage.setItem('nearbystore', JSON.stringify(localData));
								
							}							
						}
					
					});
				}else if(typeof data === 'object' && data.length === 0){
					setSearchAddress(saddress);
					setSearchCountry(countryCode);
					setStores([]);
					setUpdate(false);
					const localData = {
						address: saddress,
						country: countryCode,
						data: []
					};
					localStorage.setItem('nearbystore', JSON.stringify(localData));
					
				}
			}catch(e){console.log(e.message)}
		}		
		
		const handleStatus = (id) => {
			const list = document.getElementById(`${id}list`).innerHTML;
			let st;
			if(list === 'DISPLAYED'){						
				st = '0';
			}else{
				st = '2';
			}
			const output = {
				'rtype': 'updatestorestatus',
				'id': id,
				'email': email,
				'status': st
			};
			onPostStatus(output);			
		};		
		
		
		const subHeaderComponentMemo = useMemo(() => {
			const handleClear = () => {
				if (filterText) {
					setResetPaginationToggle(!resetPaginationToggle);
					setFilterText('');
				}
			};
			
			return (
				<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
			);
		}, [filterText, resetPaginationToggle]);
				
		const onPostStatus = async (param) =>
		{	
			try{				
				const header = await utils.genHeader();
				const dataToPost = JSON.stringify(param);
				const method = 'POST';			
				const res = await utils.fetchURL(merchantURL, method, dataToPost, header);
				const data = await res.json();
				if(data.msg === 'Token Updated'){
					localStorage.setItem('__adel_token', data?.token);
					message.info('Please resubmit');
					return;
				}				
				if(data.data === 'Successful'){
					setUpdateCategory(true);
					const list = document.getElementById(`${param.id}list`);
					if(param.status === '2'){						
						list.innerHTML = 'DISPLAYED';
					}else{
						list.innerHTML = 'HIDDEN';
					}
				}else{
					message.error(data.msg);
				}
			}catch(e){}
		}
				
		const onSubmitAdd = (e) => 
		{
			e.preventDefault();
			var sAddress, msg, sCountry;
			document.getElementById('feedback').innerHTML = '';
			document.getElementById('feedback').className = '';			
			sAddress = document.getElementById('searchAddress').value;	
			sCountry = document.getElementById('searchCountry').value;
			
			if (!sAddress) {
				msg = 'Enter Address';
				document.getElementById('feedback').innerHTML = msg;
				document.getElementById('feedback').className = 'incsizespan_error center';
				return false;
			}			
			if (!sCountry) {
				msg = 'Please Select Country';
				document.getElementById('feedback').innerHTML = msg;
				document.getElementById('feedback').className = 'incsizespan_error center';
				return false;
			}	
			
			fetchStores(sAddress, sCountry);
		}
		
		const handleSwitch = async(hqId) => {		
			if(hqId){
				const index = await params?.adminControlledStores?.findIndex((store) => store?.storeId === hqId);
				if(index >= 0){
					const nemail = await params?.adminControlledStores[index]?.storeEmail;
					await setUser(nemail); 
					await setHqId(hqId);
					await message.info(`You are in ${params?.adminControlledStores[index]?.storeName}`);
					//await setNewLoc('manage-store');
				}
			}		
		};
				
		createTheme('solarized', {
			text: {
				primary: '#4fa607',
				secondary: '',
			},
			background: {
				default: '',
			},
			context: {
				background: '#cb4b16',
				text: '#cdd6d9',
			},
			divider: {
				default: '#4fa607',
			},
			action: {
				button: 'rgba(0,0,0,.54)',
				hover: 'rgba(0,0,0,.08)',
				disabled: 'rgba(0,0,0,.12)',
			},
		}, 'light');
		
		const columns = [
			{
				name: <div style={{fontSize: 17, fontWeight: 500, color: '#000000'}}>ID</div>,
				selector: row => row.hqId,
				sortable: false,
				maxwidth: '50px',
				wrap: true,
				style: {
					fontSize: '16px',
					fontWeight: 500,
				},
			},
			{
				name: <div style={{fontSize: 17, fontWeight: 500, color: '#000000'}}>Name</div>,
				selector: row => <a href={`https://errandlocal.com/shop${row.navLink}`} target="_blank">{row.name}</a>,
				sortable: false,
				maxwidth: '800px',
				wrap: true,
				style: {
					fontSize: '16px',
					fontWeight: 500,
				},
			},
			{
				name: <div style={{fontSize: 17, fontWeight: 500, color: '#000000'}}>Address</div>,
				selector: row => row.address,
				sortable: false,
				maxwidth: '800px',
				wrap: true,
				style: {
					fontSize: '16px',
					fontWeight: 500,
				},
			},
			{
				name: <div style={{fontSize: 17, fontWeight: 500, color: '#000000'}}>Accepting</div>,
				selector: row => row.orderSwitch,
				sortable: false,
				maxwidth: '800px',
				wrap: true,
				style: {
					fontSize: '16px',
					fontWeight: 500,
				},
			},
			{
				name: <div style={{fontSize: 17, fontWeight: 500, color: '#000000'}}>Status</div>,
				selector: row => <span id={`${row.id}list`}>{row.features}</span>,
				sortable: false,
				maxwidth: '800px',
				wrap: true,
				style: {
					fontSize: '16px',
					fontWeight: 500,
				},
			},
			{
				cell: (row) => <div className="dropdown"><Link to="#" data-bs-toggle="dropdown" className="btn btn-light"><i className="fas fa-ellipsis-h"></i></Link><div className="dropdown-menu"><Link className="dropdown-item text-info" to="#" onClick={()=>handleSwitch(row.hqId)}>Edit Store</Link><Link className="dropdown-item text-danger" to="#" onClick={()=>handleStatus(row.id)}>Update Status</Link></div></div>,
				width: '70px',
				right: 0,
			},
		];	
		
		
		return (
			<Content style={{ margin: "24px 16px 0", fontFamily: "Lato" }}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>Nearby Stores</h2>
			<span style={{ opacity: 0.5 }}>List of stores nearby.</span>
			<Divider />
			
			{/*<Category pcount={pcount} onPostCategory={onPostCategory} onPostDeleteCategory={onPostDeleteCategory} update={updatecategory} setUpdate={setUpdateCategory} /> */}
			
			<section className="content-main">
			<div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
			<div className="content-header1">			
			<div><div onClick={()=> navigate("/")} className="btn btn-primary">Back to dashboard</div></div>
			</div>
			
			<Counter pcount={pcount} />
			</div>
			
			<div className="card shadow-sm">
			<div className="card-body">
			<div className="row">
			{/* Create category <CreateCategory onPostCategory={onPostCategory} />*/}
			
			<div className="col-md-12 col-lg-4">			
			<div className="mb-4">
			<label htmlFor="categoryName" className="form-label">
			<b>Search Address</b>
			</label>
			<input className="form-control py-3 tags-editor" type="text" name="searchAddress" id="searchAddress" placeholder="Search Address" title="Search Address" value={searchAddress} onChange={(e) => setSearchAddress(e.target.value)} /><br/>
			<select className="form-control py-3 tags-editor" name="searchCountry" title="Search Country" id="searchCountry" value={searchCountry} onChange={(e) => setSearchCountry(e.target.value)}>
				<option key={'default'} value="">Select a country</option>
				<option key={'us'} value="US">United States</option>
				<option key={'ca'} value="CA">Canada</option>
			</select>
			</div>
			<div className="incsizespan center" id="feedback">
			
			</div>
			<div className="d-grid">
			<button className="btn btn-primary py-3" onClick={onSubmitAdd}>Search</button>
			</div>
			</div>
			
			
			
			{/* Categories table <CategoriesTable onPostDeleteCategory={onPostDeleteCategory} update={update} setUpdate={setUpdate}/>*/}
			
			<div className="col-md-12 col-lg-8">
			<header className="card-header bg-white">
			<div className="row gx-3 py-3">
			<div className="col-lg-4 col-md-6 me-auto">				
			
			</div>
			<div className="col-lg-4 col-6 col-md-6">
			{subHeaderComponentMemo} 
			</div>
			</div>
			</header>
			<DataTable
			columns={columns}
			data={filteredItems}
			pagination
			paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
			striped
			responsive
			theme="solarized"
			/>						
			</div>
			
			</div>
			</div>
			</div>
			</section>
			
		  </div>
			</Content>
		);
		
};

export default NearbyStoreScreen;
