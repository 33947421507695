import React, {useEffect, useState} from "react";
import { Layout, Breadcrumb, Divider, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { utils } from "../../Utils";

const ListingScreen = ({pcount}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	const merchantURL = utils.MERCHANT_URL;
	const merchantCALL = utils.MERCHANT_CALL;
	const SEARCH_STORE = utils.SEARCH_STORE;
	const PROCESS_STORE = utils.PROCESS_STORE;
	
	const { Content } = Layout;
  
	const breadcrumbItems = [
		{ breadcrumbName: "Listing Request", title: "Listing Request" },
	];
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("aduseremail");
		const storeId = localStorage.getItem("aduserstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Listing Requests | Restaurant | ErrandLocal";
	}, []);
	
	useEffect(() => {
	  if (email && email !== 'undefined') {
		const interval = setTimeout(() => {
		  onGetListing();
		}, 100);
		return () => clearTimeout(interval); 
	  }
	}, [email]);
	
	
	const onGetListing = async (param) =>
	{
		try{
			const resp = await SearchStore('', 'getlist');
			if (resp.data) {
				const jsonData = resp.data;
				const storeOptionsContainer = document.getElementById('searchPendingStatus');	
				storeOptionsContainer.innerHTML = '';
				let label = '';
				storeOptionsContainer.appendChild(document.createElement('br'));
				if(jsonData.length > 0){
					jsonData.forEach((store, index)=> {
						const radioInput = document.createElement('button');
						radioInput.className = 'btn btn-warning';
						radioInput.id = 'listradio'+index;
						radioInput.name = 'storeListOption'; // Set the same name for all radio inputs
						radioInput.value = 'Done';
						radioInput.textContent = 'Done';
						radioInput.style = "margin-right: 10px";
						radioInput.setAttribute('dataname', store.storeName);
						
						radioInput.addEventListener('click', async() => {		
							if(window.confirm('Are you sure you want to update.')){
								const data = {
									rtype: 'updatelist',
									storeName: store.storeName, 
									storeAddress: store.storeAddress
								};
								const info = await postData(data);
								if(info.data){
									onGetListing();
								}
							}										
						});

						label = document.createElement('label');					
						label.textContent = store.storeMsg ? `${store.storeName} - ${store.storeAddress} | ${store.storeMsg}` : `${store.storeName} - ${store.storeAddress}`;
						label.style = "cursor: pointer";
		
											
						storeOptionsContainer.appendChild(radioInput);
						storeOptionsContainer.appendChild(label);					
						storeOptionsContainer.appendChild(document.createElement('br'));
						storeOptionsContainer.appendChild(document.createElement('br'));
						
					});
					document.getElementById('searchPendingStatus').className = 'alert-info';
					document.getElementById('searchPendingStatus').style.padding = '20px';
					
				}else{
					document.getElementById('searchPendingStatus').className = 'alert-danger';
					label = document.createElement('span');
					label.textContent = 'Data not found.';
						
					storeOptionsContainer.appendChild(label);
				}			
			
				storeOptionsContainer.appendChild(document.createElement('br'));
			}		
		}catch(e){}
	}	
	
	function SearchStore(searchinfo, type) {
		if(!localStorage.getItem('__adel_token')){
			message.error('Invalid token');
			return false;
		}
		const token = localStorage.getItem('__adel_token');
		
		var ajax = {
			post : function(url, data, headers) {
				return new Promise(function(resolve, reject) {
					var xhr = new XMLHttpRequest();
					xhr.open('POST', url, true);

					xhr.onload = function() {
						if (xhr.status != 200) 
							return reject(`Response Status: ${xhr.status}\n${xhr.responseText}`);

						resolve(JSON.parse(xhr.responseText));
					}

					xhr.onerror = function() {
						reject('Failed to connect to remote server');
					}
					xhr.setRequestHeader('Authorization', token);
					xhr.send(JSON.stringify(data));
				})
			}
		}

		const data = {
			type : type,
			searchinfo : searchinfo
		}

		return ajax
			.post(SEARCH_STORE, data, {
				'Authorization': token
			})
			
			.then(function(resp) {
				return resp;
			})
	}

	function postData(dataPost) {
		if(!localStorage.getItem('__adel_token') && dataPost.rtype !== 'login'){		
			message.error('Invalid token');
			return false;
		}
		
		let token = '';
		if(localStorage.getItem('__adel_token')){
			token = localStorage.getItem('__adel_token');
		}
		
		var ajax = {
			post : function(url, data, headers) {
				return new Promise(function(resolve, reject) {
					var xhr = new XMLHttpRequest();
					xhr.open('POST', url, true);

					xhr.onload = function() {
						if (xhr.status != 200) 
							return reject(`Response Status: ${xhr.status}\n${xhr.responseText}`);

						resolve(JSON.parse(xhr.responseText));
					}

					xhr.onerror = function() {
						reject('Failed to connect to remote server');
					}
					xhr.setRequestHeader('Authorization', token);
					xhr.send(JSON.stringify(data));
				})
			}
		}

		return ajax
			.post(PROCESS_STORE, dataPost, {
				'Authorization': token
			})
			
			.then(function(resp) {
				let error;
				if(resp.data){
					error = resp.data;
				}else if(resp.msg){
					error = resp.msg;
				}else {
					error = 'Unknown issue';
				}
				message.info(error);
				return resp;							
			})
	}

	return (
		<Content style={{ margin: "24px 16px 0", fontFamily: "Lato" }}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>Listing Requests</h2>
			<span style={{ opacity: 0.5 }}>List of all requested store.</span>
			<Divider />
			<div id='searchPendingStatus'></div>
		  </div>
		</Content>
	);
	
};

export default ListingScreen;
