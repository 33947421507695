import React, { useRef, useEffect, useState, useCallback } from "react";
import { utils } from "../../Utils";

const MapList = ({ markers, height, width, searchCoordinate, title, handleSelect, selected }) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const marker1 = utils.MAP_MARKER_1;
  const marker2 = utils.MAP_MARKER_2;
	const allowedArr = ['destination', 'store', 'assigned'];
	  
  const loadMap = useCallback(async() => {
	  try{
		  
		  if(!mapRef.current){
			  console.log('map current error');			  
			  return false;
		  }
		  const bounds = new window.google.maps.LatLngBounds();
		  const newMap = new window.google.maps.Map(mapRef.current, {
			zoom: 17,
			gestureHandling: 'greedy',
			disableDefaultUI: true, 
			zoomControl: true, 
			zoomControlOptions: {position: window.google.maps.ControlPosition.LEFT_TOP,},
			styles: [
				{
				  featureType: "poi",
				  elementType: "labels",
				  stylers: [{ visibility: "off" }],
				},
				{
				  featureType: "transit",
				  elementType: "labels",
				  stylers: [{ visibility: "off" }],
				},
			],
		  });
		  
		  markers.forEach(async(marker, index) => {
				if(!marker?.lat){
					console.log('coord error');			  
					return false;
				}
				const position = { lat: marker?.lat, lng: marker?.lng };
				const newMarker = new window.google.maps.Marker({
					position,
					map: newMap,
					icon: marker1,
				});

				bounds.extend(position);
								
				const infoButton = document.createElement("div");
				await infoButton.classList.add("contentInfo");				
				
				if(marker?.driverId){
					if(selected === marker?.driverId){
						await infoButton.classList.add("contentInfo_selected");
					}
					const infoButtonInner = document.createElement("div");				
					const infoButtonInnerB = document.createElement("b");
					infoButtonInnerB.textContent = '#'+(index - 1);
					await infoButtonInner.appendChild(infoButtonInnerB);
					await infoButton.appendChild(infoButtonInner);
					const infoButtonInner2 = document.createElement("span");
					await infoButtonInner2.classList.add("pointer");
					infoButtonInner2.textContent = 'Get Options';
					await infoButton.appendChild(infoButtonInner2);	
					
					infoButtonInner2.addEventListener('click', () => {
						handleSelect(marker?.driverId);
					});				
				}else if(marker?.title === 'store'){
					const infoButtonInner = document.createElement("div");
					const infoButtonInnerB = document.createElement("b");
					infoButtonInnerB.textContent = 'Pickup';
					await infoButtonInner.appendChild(infoButtonInnerB);
					await infoButton.appendChild(infoButtonInner);
				}else if(marker?.title === 'destination'){
					const infoButtonInner = document.createElement("div");
					const infoButtonInnerB = document.createElement("b");
					infoButtonInnerB.textContent = 'Delivery';
					await infoButtonInner.appendChild(infoButtonInnerB);
					await infoButton.appendChild(infoButtonInner);
				}				
				marker.info = infoButton;				

				const infowindow = new window.google.maps.InfoWindow({
					content: marker?.info,
				});
				
				if(allowedArr.indexOf(marker?.title) !== -1){
					newMarker.setIcon(marker2);					 
				}else{
					newMarker.setIcon(marker1);	
				}
				infowindow.open(map, newMarker);
		  });
		  //console.log("step 4...");
		  const adjustButton = document.createElement("div");
		  adjustButton.classList.add("search-area-button");
		  adjustButton.textContent = title;
		  newMap.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
			adjustButton
		  );
		  adjustButton.addEventListener("click", () => {
			// Code to adjust the marker to the address
			//console.log("Adjusting marker to address...");
		  }); 
		  
		  newMap.fitBounds(bounds);
		  
		  newMap.addListener("click", (event) => {
			const newLat = event.latLng.lat();
			const newLng = event.latLng.lng();
			//console.log("New coordinates:", newLat, newLng);
			searchCoordinate(newLat, newLng);
		  });
		  
		  setMap(newMap);
	  }catch(e){console.log('map error =>', e.message)}
	  
  }, [markers, map, searchCoordinate, marker1, marker2, mapRef.current]); 
    
  const loadAPI = useCallback(async() => {
	  try{
	/*
		  const mapKey = await utils.GOOGLE_API_KEY;
			//console.log('map errorC =>', mapKey);
		  const script = document.createElement("script");
		  //&callback=initMap
		  script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}`;
		  script.defer = true;
		  script.onload = () => {
				loadMap();
		  };
		  document.body.appendChild(script);
			*/
	  }catch(e){
		  console.log('map error2 =>', e.message);
	  }
  }, []);	
  
  const Init = useCallback(() => {
	  if (markers && markers.length > 0) 
		{
			if(window?.google?.maps){
				//console.log('map errorA =>');
				loadMap();
				
			}else{
				//console.log('map errorB =>');
				loadAPI();				
			}		
	  }
  }, [markers]);
  
  useEffect(() => {			
		window.initMap = Init();
		return () => {
			delete window?.initMap;
		};	
  }, [Init]); 
	
	if(window?.google?.maps){
		return (<div ref={mapRef} style={{ height, width, outline: 'none' }} />);
	}
	
};

export default MapList;