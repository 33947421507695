import React, {useEffect} from "react";
import { Layout, Breadcrumb, Divider } from "antd";
import { AddItem } from "../../Components";
import { BarsOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";

const AddItemScreen = ({pcount, params, loaded}) => {
	
  useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Add Menu Item | Restaurant | ErrandLocal";
  }, []);
  
  const { Content } = Layout;
  
  const breadcrumbItems = [
    { breadcrumbName: "Menu", title: "Menu" },
    { breadcrumbName: "Create", title: "Create" },
  ];

  return (
	<Content style={{ margin: "24px 16px 0", fontFamily: "Lato" }}>
	  <div
		style={{
		  marginBottom: 16,
		  display: "flex",
		  flexDirection: "row",
		  justifyContent: "space-between",
		}}
	  >
		<Breadcrumb items={breadcrumbItems} />
	  </div>
	  <div
		className="site-layout-background"
		style={{
		  padding: 24,
		  minHeight: 360,
		  backgroundColor: "#f7faf6",
		}}
	  >
		<h2 style={{ color: "#001529" }}>Create New Item</h2>
		<span style={{ opacity: 0.5 }}>To create new item, click on the edit icon under the "Action" column.</span><br/><span style={{ opacity: 0.5 }}>Addons and descriptions can also be added, when you click on the "+" icon.</span>
		<Divider />
		<AddItem pcount={pcount} params={params} loaded={loaded}/>
	  </div>
	</Content>
  );

};

export default AddItemScreen;
