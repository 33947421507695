import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { Counter } from "../../Components";
import { utils } from "../../Utils";
import { Divider, Modal, Button, message } from 'antd';

const rangeOptions = [
	
	{
        id: 0,
        label: "Moving to another location",
        value: "Moving to another location"
    },
	{
        id: 1,
        label: "I'm not using the account anymore",
        value: "I'm not using the account anymore"
    },
	{
        id: 2,
        label: "I have another account",
        value: "I have another account"
    },
	{
        id: 3,
        label: "I want create new account",
        value: "I want create new account"
    },
	{
        id: 4,
        label: "Account security concerns - Unauthorized activity",
        value: "Account security concerns - Unauthorized activity"
    },
	{
        id: 5,
        label: "Privacy concerns",
        value: "Privacy concerns"
    },	
	{
        id: 6,
        label: "I have open issues with ErrandLocal",
        value: "I have open issues with ErrandLocal"
    },
	{
        id: 7,
        label: "I don't want to provide a reason",
        value: "I don't want to provide a reason"
    },	
];

const UserMain = ({params, onPostProfile, onCloseLocation}) => {
	
	const [categories, setCategories] = useState([]);
	const [storeLocation, setStoreLocation] = useState('');
	const [storeParams, setStoreParams] = useState(params);
	const [storeCategory, setStoreCategory] = useState('');
	const [storeDescription, setStoreDescription] = useState('');
	const [primaryPhone, setPrimaryPhone] = useState('');
	const [secondaryPhone, setSecondaryPhone] = useState('');	
	const [monopen, setMonOpen] = useState('');
	const [monclose, setMonClose] = useState('');	
	const [tueopen, setTueOpen] = useState('');
	const [tueclose, setTueClose] = useState('');	
	const [wedopen, setWedOpen] = useState('');
	const [wedclose, setWedClose] = useState('');	
	const [thuropen, setThurOpen] = useState('');
	const [thurclose, setThurClose] = useState('');	
	const [friopen, setFriOpen] = useState('');
	const [friclose, setFriClose] = useState('');	
	const [satopen, setSatOpen] = useState('');
	const [satclose, setSatClose] = useState('');	
	const [sunopen, setSunOpen] = useState('');
	const [sunclose, setSunClose] = useState('');
	const [minTime, setMinTime] = useState('');
	const [maxTime, setMaxTime] = useState('');
	const [minOrder, setMinOrder] = useState('');
	const [maxOrder, setMaxOrder] = useState('');
	const [itemsel, setItemSel] = useState([]);	
	const [selectedItem, setSelectedItem] = useState(null);
	const [storeId, setStoreId] = useState('');
	const [email, setEmail] = useState('');
	const [storeImage, setStoreImage] = useState('');
	const [reason, setReason] = useState('');
	const [modalVisible, setModalVisible] = useState(false);
	const [loading, setLoading] = useState(false);
	const [storeImageFile, setStoreImageFile] = useState([]);
	const [storeBackgroundImage, setStoreBackgroundImage] = useState('');
	const [storeBackgroundImageFile, setStoreBackgroundImageFile] = useState([]);
	const fileInputRef = useRef(null);
	const fileBgInputRef = useRef(null);
	const merchantCALL = utils.MERCHANT_CALL;
	const loggedemail = localStorage.getItem('adloguseremail');
	
	const options = storeParams?.locations.map(location => ({
		value: location.storeId,
		label: location.storeAddress+', '+location.storeCity+', '+location.storeState
	}));
		
	useEffect(() => 
	{		
		const storeId = localStorage.getItem('aduserstoreId');
		const email = localStorage.getItem('aduseremail');
		setStoreId(storeId);
		setEmail(email);
	}, []);
	
	useEffect(() => 
	{		
		if (localStorage.getItem('aduserstoreId')) {
			const interval = setTimeout(() => {
				setStoreParams(params);
				const sortedStoreNames = params?.categoryOptions.sort((a, b) => a.name.localeCompare(b.name));
				setCategories(sortedStoreNames);
			}, 100);
			return () => clearTimeout(interval); 
		}
	}, [params]);
	
	useEffect(() => {
	  if(!storeLocation && document.getElementById('toggleDisplay')){
		  clearField();
		  return;
	  }
	  if(storeLocation === 'all' && document.getElementById('toggleDisplay')){
		  clearField();
		  document.getElementById('toggleDisplay').style.display = 'block';
		  return;
	  }
	  if (email && email !== 'undefined' && storeLocation && storeLocation !== 'undefined') {
		const interval = setTimeout(() => {
		  fetchStoreUser(email, storeLocation);
		}, 100);
		return () => clearTimeout(interval); 
	  }
	}, [email, storeLocation]);
	
	const handleModalCancel = () => {
		setModalVisible(false);
	};
  
	const handleModalOk = () => {		
		closeLocation();
	};
	
	const handleCloseLocation = () => {
		setModalVisible(true);		
	};
	
  
	const getButton = () => {
	  const size = 'large';
	  				  
	  return [
		<Button
		  size={size}
		  onClick={handleModalCancel}
		  key={uuidv4()}
		>
		  CANCEL
		</Button>,
		<Button
		  size={size}
		  type="primary"
		  onClick={()=> handleModalOk()}
		  key={uuidv4()}
		  danger
		>
		  CLOSE LOCATION
		</Button>,
	  ];
    };
	
	
	const fetchStoreUser = async (email, locationId) => 
	{
		document.getElementById('toggleDisplay').style.display = "none";
		if(!email){return;}
		if(!locationId){return;}
		setLoading(true);
		const header = await utils.genHeader();
		const target = await merchantCALL+'?request=store&user='+email+'&locationId='+locationId+'&categories=true&locations=false';
		const data = await utils.fetchURL(target, 'GET', '', header);
		const paramsFromServer = await data.json();
		//setStoreParams(paramsFromServer);
		setStoreCategory(paramsFromServer?.category);
		
		const nparams = paramsFromServer?.locationInfo[locationId];
		setPrimaryPhone(nparams?.phone ?? '');
		setSecondaryPhone(nparams?.secondaryphone ?? '');
		setStoreDescription(nparams?.description ?? '');
		setStoreImage(nparams?.avatar ?? '');
		setStoreBackgroundImage(nparams?.bgImage ?? '');
		setMinOrder(nparams?.minOrder ?? '');
		setMaxOrder(nparams?.maxOrder ?? '');
		setMinTime(nparams?.minTime ?? '');
		setMaxTime(nparams?.maxTime ?? '');
		if(nparams?.opendays[0])
		{
			setItemSel(nparams?.opendays);
			let day, hours = nparams?.hours;
			for(let i=0; i<nparams?.opendays?.length; i++){
				day = nparams?.opendays[i];
				if(day === 'mon'){
					setMonOpen(parseFloat(hours[day]['open']));
					setMonClose(parseFloat(hours[day]['close']));
				}else if(day === 'tue'){
					setTueOpen(parseFloat(hours[day]['open']));
					setTueClose(parseFloat(hours[day]['close']));
				}else if(day === 'wed'){
					setWedOpen(parseFloat(hours[day]['open']));
					setWedClose(parseFloat(hours[day]['close']));
				}else if(day === 'thur'){
					setThurOpen(parseFloat(hours[day]['open']));
					setThurClose(parseFloat(hours[day]['close']));
				}else if(day === 'fri'){
					setFriOpen(parseFloat(hours[day]['open']));
					setFriClose(parseFloat(hours[day]['close']));
				}else if(day === 'sat'){
					setSatOpen(parseFloat(hours[day]['open']));
					setSatClose(parseFloat(hours[day]['close']));
				}else if(day === 'sun'){
					setSunOpen(parseFloat(hours[day]['open']));
					setSunClose(parseFloat(hours[day]['close']));
				}
			}
		}else{			
			setItemSel([]);
			setMonOpen(100);
			setMonClose(100);
			setTueOpen(100);
			setTueClose(100);
			setWedOpen(100);
			setWedClose(100);
			setThurOpen(100);
			setThurClose(100);
			setFriOpen(100);
			setFriClose(100);
			setSatOpen(100);
			setSatClose(100);
			setSunOpen(100);
			setSunClose(100);
		}
				
		if(document.getElementById('toggleDisplay')){			
			document.getElementById('toggleDisplay').style.display = "block";
			/* const target = await merchantCALL+'?request=store&user='+email;
			const data = await utils.fetchURL(target, 'GET', '', header);
			const paramsLocations = await data.json();
			setStoreParams(paramsLocations); */
		}
	}
	
	
	const clearField = async () => 
	{
		document.getElementById('toggleDisplay').style.display = "none";
		setPrimaryPhone('');
		setSecondaryPhone('');
		setStoreDescription('');
		setStoreImage('');
		setStoreBackgroundImage('');
		setMinOrder('');
		setMaxOrder('');
		setMinTime('');
		setMaxTime('');
		setItemSel([]);		
	}
	
	const handleItemClick = (item) => 
	{
		selectedItem === item ? setSelectedItem(null) : setSelectedItem(item);
		itemsel.push(item);		
		setItemSel(itemsel);	
	}
	const handleItemClick_ = (item) => 
	{
		let itemlen = itemsel.length;
		let temp = [];
		for(var i=0; i<itemlen; i++){
			if(itemsel[i] !== item){
				temp.push(itemsel[i]);
			}
		}
		setItemSel(temp);
		return false;
	}
	
	function openFileModal() {
		fileInputRef.current.click();
	}
	
	function openBgFileModal() {
		fileBgInputRef.current.click();
	}

	
	function handleFileInputChange(event) {
		const file = event.target.files[0];
		if (file) {
		  //filelist.push(file);
		  setStoreImage(file.name);
		  setStoreImageFile([file]);
		}
	}
	
	function handleBgFileInputChange(event) {
		const file = event.target.files[0];
		if (file) {
		  setStoreBackgroundImage(file.name);
		  setStoreBackgroundImageFile([file]);
		}
	}
	
	function handlePaste(event) {
		event.preventDefault();
	}

	function handleKeyDown(event) {
		event.preventDefault();
	}
	
	
	const onSubmitUpdate = (e) => 
	{
		e.preventDefault();
		let output, msg, storeImg, storeBgImg;
		document.getElementById('feedback').innerHTML = "";
		
		if(storeLocation !== 'all')		
		{
			const index = storeParams?.locations.findIndex((store) => store.storeId === storeLocation);
			if (index === -1) {
				message.error('Unable to find store');
				return;
			}
			const nparams = storeParams?.locations[index];		
			//const nparams = storeParams?.locationInfo[storeLocation];		
			if(storeImage !== ''){
				storeImg = storeImage;
			}else if(nparams?.avatar){
				storeImg = nparams?.avatar;
			}else{
				storeImg = '';
			}
			
			if(storeBackgroundImage !== ''){
				storeBgImg = storeBackgroundImage;
			}else if(nparams?.bgImage){
				storeBgImg = nparams?.bgImage;
			}else{
				storeBgImg = '';
			}
		}
		else
		{
			if(storeImage !== ''){
				storeImg = storeImage;
			}else{
				storeImg = '';
			}
			
			if(storeBackgroundImage !== ''){
				storeBgImg = storeBackgroundImage;
			}else{
				storeBgImg = '';
			}
		}
		
		if(storeLocation !== 'all')		
		{
			if (!primaryPhone) {
				msg = 'Enter primary phone';
				document.getElementById('feedback').innerHTML = msg;
				document.getElementById('feedback').className = 'incsizespan_error center mb-2';
				return false;
			}else if (!secondaryPhone) {
				msg = 'Enter secondary phone';
				document.getElementById('feedback').innerHTML = msg;
				document.getElementById('feedback').className = 'incsizespan_error center mb-2';
				return false;
			}
		}
		
		
		if (!storeCategory) {
			msg = 'Please select store category';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!minTime) {
			msg = 'Enter pickup time';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!maxTime) {
			msg = 'Enter delivery time';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!minOrder) {
			msg = 'Enter minimum order amount';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (!maxOrder) {
			msg = 'Enter maximum order amount';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}else if (itemsel.length < 1) {
			msg = 'Select applicable days';
			document.getElementById('feedback').innerHTML = msg;
			document.getElementById('feedback').className = 'incsizespan_error center mb-2';
			return false;
		}
		
		let opendata = [];
		
		for(let i=0; i<itemsel.length; i++){
			const val = itemsel[i];
			let openentry;
			if(val === 'mon'){
				openentry = {day: val, openTime: monopen, closeTime: monclose};
				opendata.push(openentry);
			}else if(val === 'tue'){
				openentry = {day: val, openTime: tueopen, closeTime: tueclose};
				opendata.push(openentry);
			}else if(val === 'wed'){
				openentry = {day: val, openTime: wedopen, closeTime: wedclose};
				opendata.push(openentry);
			}else if(val === 'thur'){
				openentry = {day: val, openTime: thuropen, closeTime: thurclose};
				opendata.push(openentry);
			}else if(val === 'fri'){
				openentry = {day: val, openTime: friopen, closeTime: friclose};
				opendata.push(openentry);
			}else if(val === 'sat'){
				openentry = {day: val, openTime: satopen, closeTime: satclose};
				opendata.push(openentry);
			}else if(val === 'sun'){
				openentry = {day: val, openTime: sunopen, closeTime: sunclose};
				opendata.push(openentry);
			}			
		}
		
		output = {
			'rtype': 'updateuser',
			'storeId': storeId,
			'locationId': storeLocation,
			'email': email,
			'logemail': loggedemail,
			'storeDescription': storeDescription,
			'storeCategory': storeCategory,
			'storeImage': storeImg,
			'storeBgImage': storeBgImg,
			'primaryPhone': primaryPhone,
			'secondaryPhone': secondaryPhone,
			'minTime': minTime,
			'maxTime': maxTime,
			'minOrder': minOrder,
			'maxOrder': maxOrder,
			'opendays': JSON.stringify(opendata),
		};
		
		if(storeImageFile.length === 1){
			output.imageFile = storeImageFile;
		}
		
		if(storeBackgroundImageFile.length === 1){
			output.bgImageFile = storeBackgroundImageFile;
		}
		
		onPostProfile(output);
	}
	
	const closeLocation = () => {		
		if(!reason){
			message.error('Please select a reason');
			return;
		}
		
		let output = {
			'rtype': 'closelocation',
			'storeId': storeId,
			'locationId': storeLocation,
			'closureReason': reason,
			'email': email,
		};
		onCloseLocation(output);
		setModalVisible(false);
	}
	
	return (
		<>
			<div className="mb-4">
				<div style={{display: 'flex', justifyContent: 'space-between'}}>
					<label htmlFor="storeLocation" className="form-label"	style={{color: 'green', fontSize: 16}}>
						<b>Select a location</b>
					</label>
					{options.length > 1 && storeLocation && storeLocation !== 'all' && (<span onClick={()=> handleCloseLocation()} style={{color: 'red', cursor: 'pointer', fontWeight: 600, fontSize: 16}}>Close this location!</span>)}
				</div>
				<select className="form-control py-3 tags-editor" name="storeLocation" title="Store Location" id="storeLocation" value={storeLocation} onChange={(e) => setStoreLocation(e.target.value)}>
					<option key={uuidv4()} value="">Select a location</option>
					<option key={uuidv4()} value="all">All locations</option>
					{options[0]?.value && options.map(option => (
					  <option key={uuidv4()} value={option.value}>{option.label}</option>
					))}
				</select>
			</div>
			<div id="toggleDisplay" style={{display: "none"}}>
				{storeLocation !== 'all' && (<>
				<div className="mb-4">
					<label htmlFor="primaryPhone" className="form-label">
						<b>Primary Contact</b> *
					</label>
					<input className="form-control py-3 tags-editor" type="text" name="primaryPhone" id="primaryPhone" placeholder="Primary Phone" title="Primary Phone" value={primaryPhone} onChange={(e) => setPrimaryPhone(e.target.value)} />
				</div>
				
				<div className="mb-4">
					<label htmlFor="secondaryPhone" className="form-label">
						<b>Secondary Contact</b> [This will be made public]*
					</label>
					<input className="form-control py-3 tags-editor" type="text" name="secondaryPhone" id="secondaryPhone" placeholder="Secondary Phone" title="Secondary Phone" value={secondaryPhone} onChange={(e) => setSecondaryPhone(e.target.value)} />
				</div>
				
				<div className="mb-4">
					<label htmlFor="storeImage" className="form-label">
						<b>Store Image</b> [Leave blank to retain current image]
					</label>
					<input className="form-control py-3 tags-editor" type="text" name="storeImage" id="storeImage" placeholder="Store Image" title="Store Image" value={storeImage} onChange={(e) => setStoreImage(e.target.value)} onFocus={openFileModal} onPaste={handlePaste} onKeyDown={handleKeyDown} />
					<input className="hidden-file" type="file" id="image_file" ref={fileInputRef} onChange={handleFileInputChange}  />
				</div>
				
				<div className="mb-4">
					<label htmlFor="storeBackgroundImage" className="form-label">
						<b>Background Image</b> [Leave blank to retain current image]
					</label>
					<input className="form-control py-3 tags-editor" type="text" name="storeBackgroundImage" id="storeBackgroundImage" placeholder="Background Image" title="Background Image" value={storeBackgroundImage} onChange={(e) => setStoreBackgroundImage(e.target.value)} onFocus={openBgFileModal} onPaste={handlePaste} onKeyDown={handleKeyDown} />
					<input className="hidden-file" type="file" id="bgimage_file" ref={fileBgInputRef} onChange={handleBgFileInputChange}  />
				</div>
				
				<div className="mb-4">
					<label htmlFor="storeDescription" className="form-label">
						<b>Store Description</b>
					</label>
					<textarea className="form-control tags-editor" type="text" rows="4" cols="50" name="storeDescription" id="storeDescription" placeholder="Store Description" title="Store Description" value={storeDescription} onChange={(e) => setStoreDescription(e.target.value)}></textarea>
				</div>
				</>)}
				
				<div className="mb-4">
					<label htmlFor="storeCategory" className="form-label">
						<b>Store Category</b> *
					</label>				
					<select className="form-control py-3 tags-editor" name="storeCategory" title="Store Category" id="storeCategory" value={storeCategory} onChange={(e) => setStoreCategory(e.target.value)}>
						<option value="">Select Store Category</option>
						{categories?.length > 0 && categories?.map((item) => {
							return <option value={item?.id} key={uuidv4()}>{item?.name}</option>
						})}	
					</select>
				</div>
				
				<div style={{'display': 'flex'}}>
					<div className="mb-4 fullhalf">				
						<label htmlFor="minTime" className="form-label">
						<b>Pickup Estimated Time</b> [Prep time in mins]*
						</label><input className="form-control py-3 full tags-editor" type="text" name="minTime" id="minTime" placeholder="Min Wait Time" title="Min Wait Time" value={minTime} onChange={(e) => setMinTime(e.target.value)} />
					</div>				
					<div className="mb-4 fullhalf-right">
						<label htmlFor="maxTime" className="form-label">
						<b>Delivery Estimated Time</b> [Prep + delivery in mins]*
						</label><input className="form-control py-3 full tags-editor" type="text" name="maxTime" id="maxTime" placeholder="Max Wait Time" title="Max Wait Time" value={maxTime} onChange={(e) => setMaxTime(e.target.value)} />
					</div>
				</div>	
				
				<div style={{'display': 'flex'}}>
					<div className="mb-4 fullhalf">				
						<label htmlFor="minOrder" className="form-label">
						<b>Min Order Amount</b> *
						</label><input className="form-control py-3 full tags-editor" type="text" name="minOrder" id="minOrder" placeholder="Min Order Amount" title="Min Order Amount" value={minOrder} onChange={(e) => setMinOrder(e.target.value)} />
					</div>				
					<div className="mb-4 fullhalf-right">
						<label htmlFor="maxOrder" className="form-label">
						<b>Max Order Amount</b> *
						</label><input className="form-control py-3 full tags-editor" type="text" name="maxOrder" id="maxOrder" placeholder="Max Order Amount" title="Max Order Amount" value={maxOrder} onChange={(e) => setMaxOrder(e.target.value)} />
					</div>
				</div>
						
				<span style={{'display': 'inline-block', 'marginTop': -30, 'marginBottom': 10}}>
					<label>
						<b>Select Applicable Days</b>
					</label>
					<ul className="opendays">
						<li id={`mon`} className={`token-item is-grey ember-view ${itemsel?.includes('mon') && 'category-item-selected'}`} key={uuidv4()} onClick={e => `${itemsel?.includes('mon') === true ? handleItemClick_('mon') : handleItemClick('mon')}`}>Mon</li>
						<li id={`tue`} className={`token-item is-grey ember-view ${itemsel?.includes('tue') && 'category-item-selected'}`} key={uuidv4()} onClick={e => `${itemsel?.includes('tue') === true ? handleItemClick_('tue') : handleItemClick('tue')}`}>Tue</li>
						<li id={`wed`} className={`token-item is-grey ember-view ${itemsel?.includes('wed') && 'category-item-selected'}`} key={uuidv4()} onClick={e => `${itemsel?.includes('wed') === true ? handleItemClick_('wed') : handleItemClick('wed')}`}>Wed</li>
						<li id={`thur`} className={`token-item is-grey ember-view ${itemsel?.includes('thur') && 'category-item-selected'}`} key={uuidv4()} onClick={e => `${itemsel?.includes('thur') === true ? handleItemClick_('thur') : handleItemClick('thur')}`}>Thur</li>
						<li id={`fri`} className={`token-item is-grey ember-view ${itemsel?.includes('fri') && 'category-item-selected'}`} key={uuidv4()} onClick={e => `${itemsel?.includes('fri') === true ? handleItemClick_('fri') : handleItemClick('fri')}`}>Fri</li>
						<li id={`sat`} className={`token-item is-grey ember-view ${itemsel?.includes('sat') && 'category-item-selected'}`} key={uuidv4()} onClick={e => `${itemsel?.includes('sat') === true ? handleItemClick_('sat') : handleItemClick('sat')}`}>Sat</li>
						<li id={`sun`} className={`token-item is-grey ember-view ${itemsel?.includes('sun') && 'category-item-selected'}`} key={uuidv4()} onClick={e => `${itemsel?.includes('sun') === true ? handleItemClick_('sun') : handleItemClick('sun')}`}>Sun</li>
					</ul>
				</span>
				
				{itemsel?.includes('mon') && (
				<div style={{'display': 'flex'}}>
					<div className="mb-4 fullhalf">				
						<label htmlFor="monopen" className="form-label">
						<b>Monday Open Time</b> *
						</label>
						<select className="form-control py-3 tags-editor" name="monopen" title="Open Time" id="monopen" value={monopen} onChange={(e) => setMonOpen(e.target.value)}>
							<option value="">Select Open Time</option>
							{utils.arrall.length > 0 && utils.arrall?.map((item) => {
								return <option value={item?.id} key={uuidv4()}>{item?.label}</option>
							})}	
						</select>
					</div>				
					<div className="mb-4 fullhalf-right">
						<label htmlFor="monclose" className="form-label">
						<b>Monday Close Time</b> *
						</label>
						<select className="form-control py-3 tags-editor" name="monclose" title="Close Time" id="monclose" value={monclose} onChange={(e) => setMonClose(e.target.value)}>
							<option value="">Select Close Time</option>
							{utils.arrall.length > 0 && utils.arrall?.map((item) => {
								return <option value={item?.id} key={uuidv4()}>{item?.label}</option>
							})}	
						</select>
					</div>				
				</div>
				)}
				
				{itemsel?.includes('tue') && (
				<div style={{'display': 'flex'}}>
					<div className="mb-4 fullhalf">				
						<label htmlFor="tueopen" className="form-label">
						<b>Tuesday Open Time</b> *
						</label>
						<select className="form-control py-3 tags-editor" name="tueopen" title="Open Time" id="tueopen" value={tueopen} onChange={(e) => setTueOpen(e.target.value)}>
							<option value="">Select Open Time</option>
							{utils.arrall.length > 0 && utils.arrall?.map((item) => {
								return <option value={item?.id} key={uuidv4()}>{item?.label}</option>
							})}	
						</select>
					</div>				
					<div className="mb-4 fullhalf-right">
						<label htmlFor="tueclose" className="form-label">
						<b>Tuesday Close Time</b> *
						</label>
						<select className="form-control py-3 tags-editor" name="tueclose" title="Close Time" id="tueclose" value={tueclose} onChange={(e) => setTueClose(e.target.value)}>
							<option value="">Select Close Time</option>
							{utils.arrall.length > 0 && utils.arrall?.map((item) => {
								return <option value={item?.id} key={uuidv4()}>{item?.label}</option>
							})}	
						</select>
					</div>				
				</div>
				)}
				
				{itemsel?.includes('wed') && (
				<div style={{'display': 'flex'}}>
					<div className="mb-4 fullhalf">				
						<label htmlFor="wedopen" className="form-label">
						<b>Wednesday Open Time</b> *
						</label>
						<select className="form-control py-3 tags-editor" name="wedopen" title="Open Time" id="wedopen" value={wedopen} onChange={(e) => setWedOpen(e.target.value)}>
							<option value="">Select Open Time</option>
							{utils.arrall.length > 0 && utils.arrall?.map((item) => {
								return <option value={item?.id} key={uuidv4()}>{item?.label}</option>
							})}	
						</select>
					</div>				
					<div className="mb-4 fullhalf-right">
						<label htmlFor="wedclose" className="form-label">
						<b>Wednesday Close Time</b> *
						</label>
						<select className="form-control py-3 tags-editor" name="wedclose" title="Close Time" id="wedclose" value={wedclose} onChange={(e) => setWedClose(e.target.value)}>
							<option value="">Select Close Time</option>
							{utils.arrall.length > 0 && utils.arrall?.map((item) => {
								return <option value={item?.id} key={uuidv4()}>{item?.label}</option>
							})}	
						</select>
					</div>				
				</div>
				)}
				
				{itemsel?.includes('thur') && (
				<div style={{'display': 'flex'}}>
					<div className="mb-4 fullhalf">				
						<label htmlFor="thuropen" className="form-label">
						<b>Thursday Open Time</b> *
						</label>
						<select className="form-control py-3 tags-editor" name="thuropen" title="Open Time" id="thuropen" value={thuropen} onChange={(e) => setThurOpen(e.target.value)}>
							<option value="">Select Open Time</option>
							{utils.arrall.length > 0 && utils.arrall?.map((item) => {
								return <option value={item?.id} key={uuidv4()}>{item?.label}</option>
							})}	
						</select>
					</div>				
					<div className="mb-4 fullhalf-right">
						<label htmlFor="thurclose" className="form-label">
						<b>Thursday Close Time</b> *
						</label>
						<select className="form-control py-3 tags-editor" name="thurclose" title="Close Time" id="thurclose" value={thurclose} onChange={(e) => setThurClose(e.target.value)}>
							<option value="">Select Close Time</option>
							{utils.arrall.length > 0 && utils.arrall?.map((item) => {
								return <option value={item?.id} key={uuidv4()}>{item?.label}</option>
							})}	
						</select>
					</div>				
				</div>
				)}
						
				{itemsel?.includes('fri') && (
				<div style={{'display': 'flex'}}>
					<div className="mb-4 fullhalf">				
						<label htmlFor="friopen" className="form-label">
						<b>Friday Open Time</b> *
						</label>
						<select className="form-control py-3 tags-editor" name="friopen" title="Open Time" id="friopen" value={friopen} onChange={(e) => setFriOpen(e.target.value)}>
							<option value="">Select Open Time</option>
							{utils.arrall.length > 0 && utils.arrall?.map((item) => {
								return <option value={item?.id} key={uuidv4()}>{item?.label}</option>
							})}	
						</select>
					</div>				
					<div className="mb-4 fullhalf-right">
						<label htmlFor="friclose" className="form-label">
						<b>Friday Close Time</b> *
						</label>
						<select className="form-control py-3 tags-editor" name="friclose" title="Close Time" id="friclose" value={friclose} onChange={(e) => setFriClose(e.target.value)}>
							<option value="">Select Close Time</option>
							{utils.arrall.length > 0 && utils.arrall?.map((item) => {
								return <option value={item?.id} key={uuidv4()}>{item?.label}</option>
							})}	
						</select>
					</div>				
				</div>
				)}
						
				{itemsel?.includes('sat') && (
				<div style={{'display': 'flex'}}>
					<div className="mb-4 fullhalf">				
						<label htmlFor="satopen" className="form-label">
						<b>Saturday Open Time</b> *
						</label>
						<select className="form-control py-3 tags-editor" name="satopen" title="Open Time" id="satopen" value={satopen} onChange={(e) => setSatOpen(e.target.value)}>
							<option value="">Select Open Time</option>
							{utils.arrall.length > 0 && utils.arrall?.map((item) => {
								return <option value={item?.id} key={uuidv4()}>{item?.label}</option>
							})}	
						</select>
					</div>				
					<div className="mb-4 fullhalf-right">
						<label htmlFor="satclose" className="form-label">
						<b>Saturday Close Time</b> *
						</label>
						<select className="form-control py-3 tags-editor" name="satclose" title="Close Time" id="satclose" value={satclose} onChange={(e) => setSatClose(e.target.value)}>
							<option value="">Select Close Time</option>
							{utils.arrall.length > 0 && utils.arrall?.map((item) => {
								return <option value={item?.id} key={uuidv4()}>{item?.label}</option>
							})}	
						</select>
					</div>				
				</div>
				)}
					
				{itemsel?.includes('sun') && (
				<div style={{'display': 'flex'}}>
					<div className="mb-4 fullhalf">				
						<label htmlFor="sunopen" className="form-label">
						<b>Sunday Open Time</b> *
						</label>
						<select className="form-control py-3 tags-editor" name="sunopen" title="Open Time" id="sunopen" value={sunopen} onChange={(e) => setSunOpen(e.target.value)}>
							<option value="">Select Open Time</option>
							{utils.arrall.length > 0 && utils.arrall?.map((item) => {
								return <option value={item?.id} key={uuidv4()}>{item?.label}</option>
							})}	
						</select>
					</div>				
					<div className="mb-4 fullhalf-right">
						<label htmlFor="sunclose" className="form-label">
						<b>Sunday Close Time</b> *
						</label>
						<select className="form-control py-3 tags-editor" name="sunclose" title="Close Time" id="sunclose" value={sunclose} onChange={(e) => setSunClose(e.target.value)}>
							<option value="">Select Close Time</option>
							{utils.arrall.length > 0 && utils.arrall?.map((item) => {
								return <option value={item?.id} key={uuidv4()}>{item?.label}</option>
							})}	
						</select>
					</div>				
				</div>
				)}
				
				
				<Divider />
				
				<div className="incsizespan center mb-2" id="feedback">
					
				</div>
				<div className="d-grid" style={{'marginBottom': 20}}>
					<button id="updateProfile" className="btn btn-primary py-3" onClick={onSubmitUpdate}>
						Update Now
					</button>
				</div>
			</div>
			<div className="order-comment">
				<Modal
					title={`Select a reason`}
					open={modalVisible}
					footer={getButton()}
					//onOk={handleModalOk}			
					onCancel={handleModalCancel}
					maskClosable={false}
					closable={true}
				>
					<Divider />
					<select className="form-control py-3 tags-editor" name="reason" title="Reason" id="reason" value={reason} onChange={(e) => setReason(e.target.value)}>
						<option key={uuidv4()} value="">Select a reason for closure</option>
						{rangeOptions[0]?.value && rangeOptions.map(option => (
						  <option key={uuidv4()} value={option.value}>{option.label}</option>
						))}
					</select>
				</Modal>		
			</div>
		</>
	)
 
};

const ManageStore = ({pcount, params, onPostProfile, storeImage, setStoreImage, openFileModal, onCloseLocation}) => {

	const navigate = useNavigate();
	
	return (
		<>
		<section className="content-main">
			<div style={{display: 'flex', alignItems: 'space-between', justifyContent: 'space-between', marginBottom: 20}}>	
				<div className="content-header1">			
					<div><div onClick={()=> navigate("/")} className="btn btn-primary">Back to dashboard</div></div>
				</div>
				
				<Counter pcount={pcount} />
			</div>
			<div className="card shadow-sm">
				<div className="card-body">
					<div className="row">
						<div className="col-md-12 col-lg-4">
							<div><div onClick={()=> navigate("/add-location")} className="btn" style={{color: '#3888fd'}}>New Location?</div></div><Divider />
							{params && (<UserMain params={params} onPostProfile={onPostProfile} onCloseLocation={onCloseLocation} />)}
						</div>						
						<div className="col-md-12 col-lg-8">
							{params?.avatarimg && (<img src={params?.avatar} alt="Store Image" width="300px" height="300px" />)}				
							{params?.avatarimg && (<div className="col-md-12 col-lg-8">NOTE: Your store will require approval to be reenabled when the image is changed.</div>)}				
						</div>
					</div>
				</div>
			</div>
		</section>
		{/*<AddFooter />*/}
		
		</>
	)

}

export default ManageStore;
