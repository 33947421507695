import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import { AddItemScreen, ItemScreen, EditItemScreen, LoginScreen, ForgotPasswordScreen, OrderScreen, OrderDetailScreen, NewOrderScreen, CategoryScreen, ChangePasswordScreen, ManageStoreScreen, AddStoreLocationScreen, SubscriptionScreen, NotFoundScreen, OrderListScreen, HomeScreen, SupportScreen, ListingScreen, AddNewStoreScreen, DriverSettlementScreen, UserScreen, DriverScreen, NearbyStoreScreen, TransferScreen } from "./Screens";
import { SideBar } from "./Components";
import { utils } from "./Utils";
import { v4 as uuidv4 } from "uuid";
import { Provider, useDispatch, useSelector } from "react-redux";
import { store } from "./Store";
import { Layout, Breadcrumb, Divider } from "antd";
import mySound from "./Audio/alert.mp3";

const App = () => {	
	
	//const navigate = useNavigate();
	const [target, setTarget] = useState('');
	const [startpoint, setStartPoint] = useState('');
	const [mainstage, setMainStage] = useState('');
	const [mstartpoint, setMStartPoint] = useState('');
	const [mstage, setMStage] = useState('');
	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [flash, setFlash] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	
	const [modalTitle, setModalTitle] = useState('');
	const [modalBody, setModalBody] = useState('');
	const [modalButton, setModalButton] = useState(false);
	const [modalButtonFunc, setModalButtonFunc] = useState();
	const [modalPage, setModalPage] = useState('');
	const [pageYear, setPageYear] = useState('2023');
	const [newLoc, setNewLoc] = useState('');
	
	const [user, setUser] = useState();
	const [hqId, setHqId] = useState();	
	const [level, setLevel] = useState('');	
	const [audioStatus, setAudioStatus] = useState(false);
	const myRef = useRef();

	const [params, setParams] = useState({});
	
	const [orders, setOrders] = useState([]);
	const [allOrders, setAllOrders] = useState([]);
	const [settlements, setSettlements] = useState([]);
	const [users, setUsers] = useState([]);
	const [drivers, setDrivers] = useState([]);
	const [merchants, setMerchants] = useState([]);
	
	const [npass, setNpass] = useState(false);	
	const [rpass, setRpass] = useState(false);
	const [click, setClick] = useState(false);
	const [update, setUpdate] = useState(false);
	const [update2, setUpdate2] = useState(false);
	const [updatedelete, setUpdateDelete] = useState(false);
	const [updatecategory, setUpdateCategory] = useState(false);
	const [updateresponse, setUpdateResponse] = useState(false);
	const [audioPlay, setAudioPlay] = useState(false);
	const [error, setError] = useState(null);
	
	const [clear, setClear] = useState(false);	
	const [pcount, setPcount] = useState('');
	const merchantCALL = utils.MERCHANT_CALL;
	let aduserstoreName = "";
	if(localStorage.getItem('aduserstoreName')){
		aduserstoreName = localStorage.getItem('aduserstoreName');
	}
	
	useEffect(() => {	  
	  const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		setPageYear(currentYear);
	}, []);
	
	useEffect(() => {
	  if (loaded && localStorage.getItem('aduserstoreId')) {
		const interval = setInterval(async() => {
			let loguser = '', getuser = {};
			if(localStorage.getItem('adloguseremail')){
				loguser = await localStorage.getItem('adloguseremail');
				getuser = await utils.getUser(loguser);
			}			
			if(getuser && getuser?.role === 'Level2'){
				setLevel(getuser?.role);
				await fetchOrders();
				await fetchAllOrders();				
			}else{
				setLevel('');	
			}
		}, 20000);
		return () => clearInterval(interval);
	  }
	}, [loaded]);
	
	useEffect(() => 
	{	
	  if (localStorage.getItem('aduserstoreId')) {
		const interval = setTimeout(async() => {
		  let loguser = '', getuser = {};
			if(localStorage.getItem('adloguseremail')){
				loguser = await localStorage.getItem('adloguseremail');
				getuser = await utils.getUser(loguser);
			}	
			if(getuser && getuser?.role === 'Level2'){
				setLevel(getuser?.role);
				await fetchOrders();
				await fetchAllOrders();
				await fetchSettlements();
				await fetchUsers();
				await fetchDrivers();			
			}else{
				setLevel('');
			}
		}, 100);
		return () => clearTimeout(interval);
	  }
		// eslint-disable-next-line
	}, []);	
	
	
	useEffect(() => 
	{
		let useremail = '';
		if(localStorage.getItem('aduseremail'))
		{
			useremail = localStorage.getItem('aduseremail');
		}
		
		let mainStoreId = '';
		if(localStorage.getItem('aduserstoreId'))
		{
			mainStoreId = localStorage.getItem('aduserstoreId');
		}
		if(useremail === 'undefined'){
			localStorage.clear();
		}else if(mainStoreId === 'undefined'){
			localStorage.clear();
		}
		
		if(utils.isValidEmail(useremail)){
			setUser(useremail);
			setHqId(mainStoreId);
		}
		// eslint-disable-next-line
	}, []);
	
	useEffect(() => 
	{
		const getURL2 = async () => {	
			var urln = window.location.pathname;
			var allowed = ['login', 'dashboard', 'products', 'forgotpassword', 'changepassword', 'bankdetails', 'profile', 'orders', 'neworders', 'category', 'storecategory', 'store', 'help', 'subscription', 'addItem', 'editItem'];
			var thisr = urln.replace("/", "");
			thisr = (urln.replace(/[^A-Z0-9]/i, "")).toUpperCase();
			if(allowed.indexOf(thisr.toLowerCase()) !== -1)
			{
				//setDlink(thisr.toLowerCase());
			}
		}		
		setInterval(getURL2, 250);
		// eslint-disable-next-line
	}, []);
	
	useEffect(() => 
	{		
	  if(utils.isValidEmail(user)){			
		  getParams(user);
	  }
		// eslint-disable-next-line
	}, [user]);
	
	useEffect(() => 
	{		
	  if(utils.isValidEmail(user)){			
		  getParams(user);
	  }
		// eslint-disable-next-line
	}, [update2]);	
	
	useEffect(() => {
    const requestPermission = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        //console.log('Audio permission granted');
        // Do something with the audio stream
				setAudioPlay(true);
      } catch (error) {
				setAudioPlay(false);
				alert('Audio permission is required');
        //console.log('Error requesting audio permission:', error.message);
      }
    };

    requestPermission();
  }, []);
	
	useEffect(() => 
	{		
	  if(aduserstoreName && newLoc){
			setNewLoc('');
		  //navigate('/'+newLoc);
		  window.location.assign('/'+newLoc);
	  }
		// eslint-disable-next-line
	}, [aduserstoreName, newLoc]);
				
	const getParams = async (email) => {
		setLoading(true);
		const paramsFromServer = await utils.fetchStoreUser(email);
		setParams(paramsFromServer);
		
		localStorage.setItem('aduseremail', paramsFromServer?.email);
		localStorage.setItem('aduserstoreId', paramsFromServer?.hqId);
		localStorage.setItem('aduserstoreName', paramsFromServer?.storeName+' - '+paramsFromServer?.country);
		localStorage.setItem('helpmessage', paramsFromServer?.helpmessage);
		localStorage.setItem('helpphone', paramsFromServer?.helpphone);
		
		setLoading(false);
		setUpdate2(true);
	}			
	
	const fetchOrders = async () => 
	{
		try{
			if(!localStorage.getItem('aduserstoreId')){
				return;
			}
			const storeId = await localStorage.getItem('aduserstoreId');
						
			if(storeId === undefined){return;}
			let ordersFromServer = await utils.fetchHqIdOrder(storeId);
			let orderlen = ordersFromServer.length;
			const orderData = await utils.cipherEncode(JSON.stringify(ordersFromServer));
			await localStorage.setItem('__adel_ord', orderData);
			const orderInfo = await utils.cipherDecode(orderData);
			//dispatch(setOrders(orderInfo));
			if(orderInfo.length > 0 && orderInfo !== orders){
				setOrders(orderInfo);
			}
			
			let islen = 0;
			let isaudio = 0;
			let orderstatus , offers; 
			for(let i=0; i<orderlen; i++)
			{
				offers = ordersFromServer[i]?.data;
				orderstatus = ordersFromServer[i].storestatus;
				
				if(offers?.length > 0 && orderstatus === 'NEW')
				{						
					isaudio++;
				}
				
				if(offers?.length > 0 && orderstatus !== 'STORE_COMPLETED')
				{
					islen++;
				}
			}
			
			if((Array.isArray(offers)) && document.getElementById('pauseaudio'))
			{				
				if(isaudio > 0 && islen >= 0)
				{
					//Play Audio
					setPcount(islen);					
					if(document.getElementById('playaudio'))
					{
						//console.log('H Play Audio');
						let urln = window.location.pathname;
						let thisr = urln.replace("/", "");
						if(thisr !== 'new-orders'){
							window.location.pathname = '/new-orders';
						}
						setFlash(true);
						audioPlay && document.getElementById('playaudio').click();
					}
				}
				else if(isaudio === 0 && islen > 0)
				{					
					//stop audio
					setPcount(islen);
					//console.log('H Pause Audio');
					setFlash(false);
					audioPlay && document.getElementById('pauseaudio').click();
				}
				
				else if(isaudio === 0 && islen === 0)
				{
					//stop audio
					setPcount('');
					setFlash(false);
					//console.log('H Pause Audio2');
					audioPlay && document.getElementById('pauseaudio').click();
				}
				else if(audioStatus)
				{
					//stop audio
					setPcount('');
					setFlash(false);
					//console.log('H Pause Audio3');
					audioPlay && document.getElementById('pauseaudio').click();
				}			
			}
		
		}catch(e){}		
	}
	
	const fetchAllOrders = async () => 
	{
		try{			
			let ordersFromServer = await utils.fetchAllOrder();
			let orderlen = ordersFromServer.length;
			const orderData = await utils.cipherEncode(JSON.stringify(ordersFromServer));
			await localStorage.setItem('__adel_allord', orderData);
			const orderInfo = await utils.cipherDecode(orderData);
			if(orderInfo.length > 0 && orderInfo !== allOrders){
				setAllOrders(orderInfo);
			}
			
			let islen = 0;
			let isaudio = 0;
			let orderstatus, offers, hqId, nemail; 
			for(let i=0; i<orderlen; i++)
			{
				offers = ordersFromServer[i]?.data;
				orderstatus = ordersFromServer[i].storestatus;
				hqId = ordersFromServer[i].hqId;				 
				
				if(offers?.length > 0 && orderstatus === 'NEW')
				{						
					isaudio++;
				}
				
				if(offers?.length > 0 && orderstatus !== 'STORE_COMPLETED')
				{
					islen++;
				}
			}
			
			if((Array.isArray(offers)) && document.getElementById('pauseaudio'))
			{				
				if(isaudio > 0 && islen >= 0)
				{
					//Play Audio
					setPcount(islen);					
					if(document.getElementById('playaudio') && hqId)
					{						
						//console.log('H Play Audio');
						let urln = window.location.pathname;						
						let thisr = urln.replace("/", "");
						if(thisr !== 'new-orders'){
							const index = await params?.adminControlledStores?.findIndex((store) => store.storeId === hqId);
							nemail = await params?.adminControlledStores[index].storeEmail;							
							await setUser(nemail); 
							await setHqId(hqId);
							window.location.pathname = '/new-orders';
						}
						setFlash(true);
						audioPlay && document.getElementById('playaudio').click();
					}
				}
				else if(isaudio === 0 && islen > 0)
				{					
					//stop audio
					setPcount(islen);
					//console.log('H Pause Audio');
					setFlash(false);
					audioPlay && document.getElementById('pauseaudio').click();
				}
				
				else if(isaudio === 0 && islen === 0)
				{
					//stop audio
					setPcount('');
					setFlash(false);
					//console.log('H Pause Audio2');
					audioPlay && document.getElementById('pauseaudio').click();
				}
				else if(audioStatus)
				{
					//stop audio
					setPcount('');
					setFlash(false);
					//console.log('H Pause Audio3');
					audioPlay && document.getElementById('pauseaudio').click();
				}			
			}
		
		}catch(e){}		
	}
	
	const fetchSettlements = async () => 
	{
		try{
			if(!localStorage.getItem('aduseremail')){
				return;
			}			
			const email = localStorage.getItem('aduseremail');			
			const header = await utils.genHeader();
			const target = await utils.DRIVER_CALL+'?settlements='+email;
			const data = await utils.fetchURL(target, 'GET', '', header);
			const settlesFromServer = await data.json();
			
			const settlesData = await utils.cipherEncode(JSON.stringify(settlesFromServer?.data));
			await localStorage.setItem('__adel_settles', settlesData);
			const settlesInfo = await utils.cipherDecode(settlesData);
			if(settlesInfo.length > 0 && settlesInfo !== settlements){
				setSettlements(settlesInfo);
			}
			
		}catch(e){}
	}
	
	const fetchUsers = async () => 
	{
		try{
			if(!localStorage.getItem('aduseremail')){
				return;
			}			
			const email = localStorage.getItem('aduseremail');			
			const header = await utils.genHeader();
			const target = await utils.ADMIN_USERS;
			const data = await utils.fetchURL(target, 'GET', '', header);
			const usersFromServer = await data.json();
			if(usersFromServer?.data.length > 0 && usersFromServer?.data !== users){
				setUsers(usersFromServer?.data);
			}
		}catch(e){}
	}
	
	const fetchDrivers = async () => 
	{
		try{
			if(!localStorage.getItem('aduseremail')){
				return;
			}			
			const email = localStorage.getItem('aduseremail');			
			const header = await utils.genHeader();
			const target = await utils.ADMIN_DRIVERS;
			const data = await utils.fetchURL(target, 'GET', '', header);
			const usersFromServer = await data.json();
			if(usersFromServer?.data.length > 0 && usersFromServer?.data !== drivers){
				setDrivers(usersFromServer?.data);
			}
			setLoaded(true);
		}catch(e){}
	}
	
	const startAudio = async() => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			myRef.current.loop = true;
			myRef.current.play();		
			setAudioStatus(true);
		} catch (error) {}		
	};

	const pauseAudio = async() => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			myRef.current.loop = false;
			myRef.current.pause();		
			setAudioStatus(false);
		} catch (error) {}
	};
				
	function cookieSet(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires="+d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function cookieGet(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for(var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}
	
	function cookieCheck(cname){
		var color = cookieGet(cname);
		if(color){
			return color;
		}else{
			cookieSet(cname, 'dark', 30);
			return 'dark';
		}
	}
	
	const { Header, Footer } = Layout;	
	
	if(user && hqId){
		return (
			<Provider store={store}>	
			<Router>
			  <AnimatePresence mode='wait'>
				<Layout style={{ minHeight: "100vh", fontFamily: "Lato" ,}}>
				  <SideBar params={params} />
				  <Layout
					className={`site-layout-sub-header-background ${flash ? ' flashing' : ''}`}
					style={{
					  backgroundColor: "#fbfcfa",
					}}
				  >
					<Header className="site-layout-background" style={{ padding: 0 }} >
					<div className="logo">
						<span style={{color: '#fff', fontFamily: "Lato", marginTop: -60}}>{`${aduserstoreName.toUpperCase()}`}</span>
					</div>
					</Header>
					
					<Routes>
					  {user && hqId && (<Route exact path="/" element={<HomeScreen pcount={pcount} key={uuidv4()} initOrders={allOrders} params={params} setUser={setUser} setHqId={setHqId} loading={loading}/>} />)}
					  {user && hqId && (<Route path="/new-orders" element={<NewOrderScreen pcount={pcount} setPcount={setPcount} key={uuidv4()} loaded={loaded} initOrders={orders} setFlash={setFlash} params={params} update2={update2} setUpdate2={setUpdate2} />} />)}
						{user && hqId && (<Route path="/driver-settlement" element={<DriverSettlementScreen pcount={pcount} initialData={settlements} loaded={loaded} key={uuidv4()} />} />)}
					  {user && hqId && (<Route path="/orders" element={<OrderScreen pcount={pcount} initOrders={orders} key={uuidv4()} />} />)}						
					  {user && hqId && (<Route path="/order/:orderId" element={<OrderDetailScreen pcount={pcount} setPcount={setPcount} key={uuidv4()} />} />)}
						{user && hqId && (<Route path="/user-list" element={<UserScreen pcount={pcount} initialData={users} loaded={loaded} key={uuidv4()} />} />)}
						{user && hqId && (<Route path="/driver-list" element={<DriverScreen pcount={pcount} initialData={drivers} loaded={loaded} key={uuidv4()} />} />)}
					  {user && hqId && (<Route path="/listings" element={<ListingScreen pcount={pcount} key={uuidv4()} />} />)}
					  {user && hqId && (<Route path="/add-item" element={<AddItemScreen pcount={pcount} key={uuidv4()} params={params} loaded={loaded} />} update2={update2} setUpdate2={setUpdate2} />)}
					  {user && hqId && (<Route path="/edit-item/:itemId" element={<EditItemScreen pcount={pcount} params={params} loaded={loaded} key={uuidv4()} update2={update2} setUpdate2={setUpdate2} />} />)}
					  {user && hqId && (<Route path="/item-list" element={<ItemScreen pcount={pcount} />} key={uuidv4()} />)}  
						
						{user && hqId && (<Route path="/nearby-stores/:address/:countryCode" element={<NearbyStoreScreen setNewLoc={setNewLoc} setUser={setUser} params={params} setHqId={setHqId} pcount={pcount} setPcount={setPcount} key={uuidv4()} />} />)}
						
						{user && hqId && (<Route path="/nearby-stores/:lat/:lng/:countryCode" element={<NearbyStoreScreen setNewLoc={setNewLoc} setUser={setUser} params={params} setHqId={setHqId} pcount={pcount} setPcount={setPcount} key={uuidv4()} />} />)}
						
						{user && hqId && (<Route path="/nearby-stores" element={<NearbyStoreScreen setNewLoc={setNewLoc} setUser={setUser} params={params} setHqId={setHqId} pcount={pcount} setPcount={setPcount} key={uuidv4()} />} />)}
						
						{user && hqId && (<Route path="/transfer" element={<TransferScreen pcount={pcount} setPcount={setPcount} key={uuidv4()} />} />)}
						
					  {user && hqId && (<Route path="/change-password" element={<ChangePasswordScreen pcount={pcount} />} key={uuidv4()} />)}  
					  {user && hqId && (<Route path="/manage-store" element={<ManageStoreScreen pcount={pcount} params={params} update2={update2} setUpdate2={setUpdate2} />} key={uuidv4()} />)}
					  {user && hqId && (<Route path="/add-location" element={<AddStoreLocationScreen pcount={pcount} params={params} update2={update2} setUpdate2={setUpdate2} />} key={uuidv4()} />)}
					  {user && hqId && (<Route path="/add-store" element={<AddNewStoreScreen pcount={pcount} params={params} setUser={setUser} setHqId={setHqId} />} key={uuidv4()} />)}
					  {user && hqId && (<Route path="/create-category" element={<CategoryScreen pcount={pcount} />} key={uuidv4()} />)}  
					  {user && hqId && (<Route path="/billing" element={<SubscriptionScreen pcount={pcount} />} key={uuidv4()} />)}  
					  {user && hqId && (<Route path="/support" element={<SupportScreen pcount={pcount} />} key={uuidv4()} />)}
					  {user && hqId && loaded && (<Route path="*" element={<NotFoundScreen key={uuidv4()} />} />)}  
					</Routes>
					<audio
						ref={myRef}
						src={mySound}
					/>
					<button id="pauseaudio" style={{height:'0.1px', width:'0.1px', left: -1000, position: 'absolute'}} onClick={pauseAudio}></button>
					<button id="playaudio" style={{height:'0.1px', width:'0.1px', left: -1000, position: 'absolute'}} onClick={startAudio}></button>
				
					<Footer
					  style={{
						textAlign: "center",
						backgroundColor: "#fbfcfa",
					  }}
					>
					{`© ${pageYear} ErrandLocal`} 
					</Footer>
			  </Layout>
			</Layout>
			  </AnimatePresence>
			</Router>
			</Provider>	
		);
	}else{
		return (
		<Provider store={store}>	
		<Router>
		  <AnimatePresence mode='wait'>
			<Routes>
			  <Route exact path="/" element={<LoginScreen setOrders={setOrders} key={uuidv4()} />} />		  
			  <Route path="/login" element={<LoginScreen setOrders={setOrders} key={uuidv4()} />} />
			  <Route path="/forgotpassword" element={<ForgotPasswordScreen key={uuidv4()} />} />
			  <Route path="*" element={<NotFoundScreen key={uuidv4()} />} />
			</Routes>				
		  </AnimatePresence>
		</Router>
		</Provider>
		);
	}
	
};

export default App;
