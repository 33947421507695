import React,{useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';

const NotFoundScreen = () => {
	
	const navigate = useNavigate();
	
  useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Resource Not Found | Restaurant | ErrandLocal";
  }, []);
	
	useEffect(() => {
		if(!localStorage.getItem('aduserstoreName')){
			navigate('/');
		}
  }, []);
  
  return (
    <div className="not-found-container">
      <h1 className="not-found-heading">404</h1>
      <p className="not-found-message">{`Oops! The page you're looking for doesn't exist.`}</p>
			<Link to="/" className="not-found-link">Go to Home Page</Link>
    </div>
  );
};

export default NotFoundScreen;