import React, {useEffect, useState} from "react";
import { Layout, Breadcrumb, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import { Users } from "../../Components";
import { useDispatch } from "react-redux";
import { utils } from "../../Utils";
import { message } from 'antd';

const UserScreen = ({pcount, initialData, loaded}) => 
{	
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [storeId, setStoreId] = useState('');
	const [users, setUsers] = useState(initialData);
	const [level, setLevel] = useState('');
	const { Content } = Layout;
  
	const breadcrumbItems = [
		{ breadcrumbName: "Users", title: "Users" },
		{ breadcrumbName: "All Users", title: "All Users" },
	];
	
	useEffect(() => 
	{	
		const email = localStorage.getItem("aduseremail");
		const storeId = localStorage.getItem("aduserstoreId");
		setEmail(email ?? '');
		setStoreId(storeId ?? '');
		// eslint-disable-next-line
	}, []);
	
	
	useEffect(() => {
		window.document.body.style.overflow = "auto";
		document.title = "Users List | ErrandLocal";
	}, []);		
	
	useEffect(() => {	  
	  if (loaded && localStorage.getItem('aduserstoreId')) {
			const interval = setInterval(async() => {
				let loguser = '', getuser = {};
				if(localStorage.getItem('adloguseremail')){
					loguser = await localStorage.getItem('adloguseremail');
					getuser = await utils.getUser(loguser);
				}			
				if(getuser && getuser?.role === 'Level2'){
					setLevel(getuser?.role);
					await fetchUsers();
				}else{
					setLevel('');	
				}
			}, 60000);
			return () => clearInterval(interval);
	  }
	}, [loaded]);
	
	const fetchUsers = async () => 
	{
		try{
			if(!localStorage.getItem('aduseremail')){
				return;
			}			
			const email = localStorage.getItem('aduseremail');			
			const header = await utils.genHeader();
			const target = await utils.ADMIN_USERS;
			const data = await utils.fetchURL(target, 'GET', '', header);
			const usersFromServer = await data.json();
			if(usersFromServer?.data.length > 0 && usersFromServer?.data !== users){
				setUsers(usersFromServer?.data);
			}
		}catch(e){}
	}	
	
	const onPostAction = async (param) =>
	{	
		try{
			//const header = await utils.genHeader();
			if(!localStorage.getItem('__adel_token')){
				message.error('Invalid token');
				return false;
			}
			const token = localStorage.getItem('__adel_token');
			const target = utils.PROCESS_STORE;			
			const res = await utils.fetchURL(target, 'POST', JSON.stringify(param), {'Authorization': token});			
						
			const data = await res.json();	
			if(data.msg === 'Token Updated'){
				//localStorage.setItem('__el_token', data?.token);
				message.info('Please resubmit');
				return;
			}
			if(data.msg === 'Success' || data.msg === 'Successful'){
				message.success('Operation was successful');
			}else{
				message.error(data.msg);
			}
		}catch(e){
			message.error(e.message);
		}
	}
	
		
	return (
		<Content style={{ margin: "24px 16px 0", fontFamily: "Lato" }}>
		  <div
			style={{
			  marginBottom: 16,
			  display: "flex",
			  flexDirection: "row",
			  justifyContent: "space-between",
			}}
		  >
			<Breadcrumb items={breadcrumbItems} />
		  </div>
		  <div
			className="site-layout-background"
			style={{
			  padding: 24,
			  minHeight: 360,
			  backgroundColor: "#f7faf6",
			}}
		  >
			<h2 style={{ color: "#001529" }}>All Users</h2>
			<span style={{ opacity: 0.5 }}>List of all registered users</span>
			<Divider />
			{users.length > 0 && (<Users pcount={pcount} users={users} onPostAction={onPostAction} />)}
		  </div>
		</Content>
	);

	
};

export default UserScreen;
